import CONSTANTS from "config/constants";

const { TITLE } = CONSTANTS;

const en = {
  title: "Simple Commerce",
  home: {
    metaTitle: TITLE,
    metaDescription: "The pet products that your customers are looking for.",
    signInButton: "Sign in now",
    enjoyBenefits: "Enjoy our benefits"
  },
  footer: {
    copyright: "©Pure Nature 2022. ",
    reserved: "All rights reserved.",
    developed: "Developed by ",
    trade: "Trade",
    faq: "Frequently asked questions",
    navigation: {
      profile: "Profile",
      settings: "Settings",
      privacy: "Privacy policies",
      terms: "Terms and conditions",
      legalDocs: "Legal Documents and Data Management",
      dangerousZones: "Delivery areas without coverage"
    }
  },
  navigation: {
    shopping: "Shopping",
    discover: "Discover",
    loyalty: "Loyalty",
    coupons: "Coupons",
    profile: "Profile",
    notification: "Notification",
    viewCategories: "View Categories",
    categories: "Categories",
    user: "User"
  },
  catalogues: {
    delivery: "Delivery",
    pickup: "Pick-Up",
    other: "Other"
  },
  common: {
    units: "c/u",
    preferences: "Preferences",
    workflows: "Purchase method",
    apply: "Apply",
    buyingIn: "Buying in",
    catalogue: "Catalogue",
    continue: "Continue",
    retry: "Retry",
    send: "Send",
    confirm: "Confirm",
    cancel: "Cancel",
    edit: "Edit",
    change: "Cambiar",
    soon: "Stay tuned, this feature is coming soon!",
    goToPay: "Go to pay",
    pay: "Pay",
    warning: "Are you sure that you want to leave?",
    add: "Add",
    update: "Update",
    accept: "Accept",
    save: "Save",
    delete: "Delete",
    keep: "Don't delete",
    goBack: "Go back",
    back: "Back",
    tryAgain: "Try again",
    enter: "Enter",
    signIn: "Sign in",
    search: "Search",
    unselected: "Not selected",
    hello: "Hello",
    ourProducts: "Our products",
    viewAll: "View all",
    repeat: "Repeat",
    view: "View",
    brand: "Brand",
    presentation: "Presentation",
    seeMore: "See more",
    seeLess: "See less",
    label: "Label",
    other: "other",
    local: "local",
    winery: "winery",
    deliveryAddress: "Delivery address",
    close: "close",
    deUnaPayRejected: "Your pay have not been processed or have been rejected",
    buyNow: "Buy now",
    account: "Create account",
    logOut: "Log out",
    seeCart: "See cart",
    deleteAccount: "Eliminar cuenta",
    iva: "All prices include IVA",
    signInForm: "Sign in"
  },
  forms: {
    name: "Name",
    lastName: "Last name",
    nameAndLastName: "Name and last name",
    documentType: "Document type",
    document: "Document",
    documentNumber: "Identity number",
    email: "E-mail",
    emailAddress: "Email address",
    emailPlaceholder: "example@email.com",
    address: "Principal street",
    secondaryAddress: "Secondary street",
    phoneNumber: "Phone number",
    password: "Password",
    passwordPlaceholder: "************",
    cardNumber: "Card number",
    cardHolder: "Card holder",
    expirationDate: "Expiration date",
    cvv: "CVV",
    dateOfBirth: "Date of birth",
    dateOfBirthPlaceholder: "DD / MM / YYYY",
    passport: "Passport",
    ci: "CI",
    commerceType: "Trade type",
    businessName: "Business name",
    saveChanges: "Save changes",
    reference: "Reference",
    addressPlaceholder: "Apartment/Office/flat",
    referencePlaceholder: "white house with big black door",
    noteLabel: "Add a delivery note (optional)",
    notePlaceholder:
      "Delivery instructions, person who receives it, schedules, etc.",
    saveAddress: "Save address",
    validDateOfBirth: "The date entered is not valid",
    autoservice: "Autoservice",
    desktop: "Desktop",
    eCommerce: "E-Commerce",
    birthdayError: "Select a valid date"
  },
  errors: {
    cardFormValues: "The card form values is required",
    additionalInfo: "The additional info user is required",
    notAuthenticated: "You are not authenticated",
    phoneIsRequired: "The phone is required",
    mainStreetIsRequired: "The main street is required",
    countryCodeIsRequired: "The country code is required",
    cardOwnerIsRequired: "The card owner is required",
    cardDocumentIsRequired: "The card document is required",
    cardDocumentAlreadyInUse: "The document already in use.",
    cardIsNotSelected: "The card is not selected",
    generic: "Something went wrong",
    errorMessage:
      "If the problem persists, contact support and we will help you solve it.",
    firebase: {
      auth: {
        wrongPassword: "Invalid data",
        userNotFound: "User not found with the provided identifier.",
        tooManyRequests:
          "Too many requests. The access to this account has been temporally disabled",
        emailAlreadyInUse: "There exist an account with this email",
        networkRequestFailed: "A network error has occurred, try again",
        operationNotAllowed:
          "The provided sign-in provider is disabled for your Firebase project",
        accountExistsWithDifferentCredential:
          "An account already exists with the same email address but different sign-in credentials",
        invalidActionCode: "The used link is invalid",
        userDisabled: "Your account has been disabled",
        invalidEmail: "Enter a valid email address",
        weakPassword: "Weak password"
      }
    },
    createAddress: "An error occurred while creating the address",
    updateAddress: "An error occurred while updating the address"
  },
  categories: {
    title: "Categories",
    empty: "We are changing our menu",
    emptyProducts: "No products",
    emptyDescription:
      "It seems there are no products here, try browsing other categories",
    emptyButton: "Explore",
    error: "Something went wrong",
    errorDescription:
      "There was an error loading the page, try reloading the page or come back later",
    errorButton: "Explore more products",
    promotion: "Promotions"
  },
  coupons: {
    points: "Accumulated points",
    metaTitle: "Coupons",
    detailTitle: "Coupons",
    add: "Add",
    apply: "Apply",
    enterCoupon: "Enter coupon",
    dateValid: "Valid until",
    availablePoints: "Available points",
    usePoints: "Use points",
    signIn: "Sign in and enjoy exclusive coupons!"
  },
  productsSearch: {
    metaTitle: "Discover",
    result: "results of",
    title: "Recommended for you",
    emptyProducts: "We found no results for ",
    emptyDescription: "Would you like to try another word?",
    explore: "Explore other products",
    error: "Something went wrong",
    errorDescription:
      "There was an error loading the page, try reloading the page or come back later",
    moreProducts: "Explore more products",
    notResult: "We found no results for",
    anotherWord: "Would you like to try another word?",
    recommendedWord: "how about you try “Care”"
  },
  profile: {
    metaTitle: "User",
    profileHeader: {
      greetings: "Hello!",
      edit: "Edit account"
    },
    profileMembership: {
      title: "Pure Nature Club",
      points: "Continue accumulating points",
      otherLevels: "Other levels",
      newMember: "You are already part of the Pure Nature Club",
      levelCard: {
        purchases: "purchases",
        button: "View membership"
      },
      currentLevel: {
        level: "Level",
        bronze: "Bronze",
        history: "Movement history",
        current: "Current",
        availableBenefits: "Available Benefits",
        enjoyBenefits: "Enjoy the benefits of your current level",
        purchases: "Purchases per year",
        silver: "Silver",
        gold: "Gold",
        benefits: "Benefits"
      },
      noMembership: {
        accumulate: "Accumulate",
        points: "Points",
        firstPurchase: "Make your first purchase and start accumulating points",
        join: "Join Now",
        benefits: "Know the benefits"
      }
    },
    profileAddress: {
      title: "Delivery address",
      addresses: "Addresses",
      add: "Add new addresses",
      maxAddress: {
        possible: "It is only possible to have",
        active: "active addresses",
        limitation: "Address data limit exceeded"
      },
      empty: {
        title: "Save your addresses",
        description: "Add a delivery address to make your first purchase"
      }
    },
    profileCoupons: {
      title: "Coupons",
      addCoupon: "Add coupon",
      add: "Add",
      successRedeemCoupon: "Successfully redeemed coupon",
      successApplyCoupon: "Successfully applied coupon",
      errorRedeemCoupon:
        "The coupon could not be added, check that the coupon is valid or has not been redeemed previously",
      available: "Available to redeem",
      expired: "Expired",
      anonymous: "Your coupon is waiting!\n Login to redeem it.",
      signIn: "Sign in",
      empty: {
        message: "There is no coupon wallet available for your user",
        title: "Add coupons",
        description:
          "With your coupons you can get discounts and many more benefits"
      }
    },
    profileUser: {
      title: "Edit my account",
      editPhoto: "Edit Photo",
      form: {
        commerce: "Type of commerce",
        genre: "Genre",
        male: "Male",
        female: "Female",
        ruc: "RUC",
        invalidPhone: "The phone number entered is not valid",
        updateErrorData: "There was a problem updating the user information",
        saveErrorData: "There was a problem saving the user information",
        savedChanges: "Successfully saved changes",
        imageError: "There was a problem uploading the selected image",
        invalidImage: "The selected image is not valid",
        imageMaxSize: "The size of the selected image must not exceed 1 Mb"
      },
      linking: {
        title: "Connect your account with your networks",
        connectFb: "Connect with Facebook",
        connectGoogle: "Connect with Google",
        disconnectFb: "Disconnect from Facebook",
        disconnectGoogle: "Disconnect from Google"
      },
      security: "Security",
      updatePassword: {
        title: "Change password",
        change: "Change your password",
        current: "Current password",
        new: "Enter the new password",
        repeat: "Repeat the new password",
        error: "Passwords do not match",
        form: {
          success: "Successful change",
          updated: "Password updated successfully",
          error: "Failed to update password"
        }
      }
    },
    profileOrders: {
      title: "Purchase history",
      downloadOrder: {
        title: "Download your order summary",
        description: "Download it here"
      },
      empty: {
        title: "You don't have orders in progress yet",
        description: "Start shopping",
        buttonTitle: "Start shopping"
      },
      error: {
        title: "There is no order in this session",
        description:
          "There is no information for the order specified in this browser"
      },
      orderInfo: {
        title: "Order Detail",
        resume: "Order Summary",
        information: "Transaction information",
        hour: "Hour",
        reference: "Reference",
        status: "Status",
        deliveryAddress: "Delivery Address",
        store: "Store",
        billing: "Billing information",
        paymentMethod: "Payment method",
        date: "Date",
        downloadSuccessTitle: "Download successful",
        downloadSuccessMessage: "Your order details have been downloaded."
      },
      orderList: {
        date: "Date",
        orderBy: "Order by"
      },
      orderPreview: {
        days: "days",
        hours: "hours",
        lastPurchases: "Last purchases",
        previous: "Previous purchases",
        pendding: "Pendding purchases",
        deliveryDate: " 72 hours"
      },
      orderStatus: {
        orderInProcess: "Order in process",
        deliveryDate: "Your order will be delivered around 72 hours",
        delivered: "Your order has been delivered!",
        rateOrder: "Rate your order",
        thankYouForRating: "Thank you for rating your order"
      },
      rateOrder: {
        title: "Rate your order",
        support: "Support",
        rate: "Rate",
        order: "Order #",
        howWasYouOrder: "How was your order?",
        genericError: "Something went wrong?",
        whatWentWrong: "Tell us what went wrong",
        poorQuality: "Poor product quality",
        notWhatAskedFor: "It was not what I asked for",
        missingOrder: "Something was missing from my order",
        deliveryTrouble: "Trouble with the delivery person",
        badPresentation: "Bad Presentation",
        others: "Others",
        whatYouLikedTheMost: "What did you like the most?",
        whatYouLiked: "Tell us what you liked",
        easyToPlaceOrder: "It was fast ",
        excellentPresentation: "Excellent presentation ",
        excellentProduct: "Excellent flavour",
        foundEverything: "Excellent attention ",
        feedback: "Thank you for your feedback!",
        comment: "Comment",
        commentPlaceholder: "Add a comment"
      }
    },
    profileStores: {
      title: "Stores",
      address: "Address: ",
      phone: "Phone",
      opens: "Opens: ",
      open: "Open",
      closed: "Closed"
    },
    profileBilling: {
      title: "Billing data",
      newBillingData: "New billing information",
      addBillingData: "Add new billing information",
      editBillingData: "Edit billing data",
      deleteBillingData: "Do you want to proceed with deleting?",
      maxBilling: {
        possible: "It is only possible to have",
        active: "active billing data",
        limitation: "Billing data limit exceeded"
      },
      empty: {
        title: "Save your data",
        description: "Add your billing information to buy faster"
      },
      modal: {
        names: "Name and last names / Business name",
        ruc: "RUC number",
        phone: "Contact number",
        useAsDefault: "Use as default",
        saveData: "Save data",
        invalidPhone: "The phone number entered is not valid",
        updateErrorData: "There was a problem updating billing data",
        saveErrorData: "There was a problem saving billing data",
        deleteErrorData:
          "An error occurred while trying to delete billing information",
        deleteInformation:
          "Once deleted you will not be able to recover this information"
      },
      form: {
        natural: "Natural person",
        legal: "Legal person"
      }
    },
    profileNotification: {
      title: "Notifications",
      enableEmailNotifications:
        "Receive notifications of the status of my orders via email",
      empty: {
        title: "You don't have any notification",
        description:
          "You will receive notifications when you have pending orders"
      },
      anonymous: "Sign in to receive your order notifications."
    },
    profilePolitics: {
      title: "Privacy configurations",
      description: "Receive notifications in your email about news and offers",
      accept: "Privacy and data use policies"
    },
    profileSettings: {
      title: "Settings",
      enableDarkMode: "Enable dark mode"
    },
    profileTerms: {
      title: "Legal terms",
      politics: "Privacy and data use policies",
      terms: "Terms and conditions",
      faq: "Frequently asked questions",
      authorizations: "Authorizations and rights",
      accounts: "Accounts, passwords and security",
      duties: "Your rights",
      privacy: "Data privacy policy",
      toppings: "Toppings",
      politicsInfo:
        "The information that THE USER provides to PET GOURMET through the Platform is governed by its Privacy Policy. Through the use of the Platform, THE USER acknowledges and accepts that any information that you transmit to PET GOURMET in any way and for any reason, will not be treated as confidential or private. In addition, THE USER accepts that any ideas, concepts, techniques, procedures, methods, systems, designs, graphics, or other materials that you transmit to PET GOURMET may be used by PET GOURMET anywhere, at any time, and for any reason.",
      politicsContentInfo:
        "Some of the functionality of the Platform, including mobile payment, location-based services and functionality, and access to the user's address book and photo library may require the transmission of user-provided information, including , without limitation, names, usernames and passwords, addresses, address, photos, financial information (such as credit card numbers), e-mail, and/or GPS location. If the user uses the functionality of said Platform, the user consents to the transmission of user information to PET GOURMET and / or its agents and authorizes PET GOURMET and / or its agents to record, process and store user information in any way necessary for the functionality of the Platform and for the purposes described in the PET GOURMET Privacy Policy.",
      termsInfo:
        "Use of the website and mobile application on which these terms reside (collectively, the “Platform”), and the features of this platform are subject to these Terms of Use (this “Agreement”), which establishes a legally binding agreement. between You (for the purposes of this instrument will be referred to as 'THE USER') and PET GOURMET S.A. under its trade name 'PET GOURMET' (for the purposes of this instrument it will be referred to simply as 'PET GOURMET'). Please read this Agreement carefully before using this Platform. If you do not agree to the terms contained in this Agreement, then do not use the Platform. Use of the Platform constitutes acknowledgment of your acceptance of this Agreement. This includes, but is not limited to, completing an electronic transaction and agreeing to the disclaimer of warranties, indemnities, and remedies, exclusions, and limitations. You acknowledge that you have read and agree to be bound by this Agreement to comply with all applicable laws, regulations and/or rules in connection with your use of the Platform.",
      termsContentInfo:
        "PET GOURMET may update this platform and this Agreement whenever it deems necessary and from time to time. Use of this platform after PET GOURMET notifies an update to the platform or this Agreement constitutes acceptance of such changes prospectively from the date of such changes.",
      termsMoreContentInfo:
        "PET GOURMET may, at its sole discretion and at any time, suspend this Platform or any part of it, with or without notice, or prevent its use with or without notice. You agree that you do not have any rights over and in this Platform and, PET GOURMET will not have any responsibility with the USER if this Platform is interrupted or if your ability to access the platform ends.",
      termsPlatformInfo:
        "You may not use the Platform for any purpose that is illegal or prohibited by this agreement. Your access to the Platform may be terminated immediately at the sole discretion of PET GOURMET, with or without notice, if you fail to comply with any provision of this agreement and/or additional conditions.",
      authorizationsInfo:
        "THE USER grants his free, explicit, unequivocal and informed consent for PET GOURMET to process his personal data and store it in a database that will be used to promote the launch of new products or marketing activities of PET GOURMET, THE USER when using this platform agrees to transfer your information to the database.",
      authorizationsContentInfo:
        "THE USER expressly authorizes the use of personal data by any means or form, whether physical or wireless.",
      authorizationsMoreContentInfo:
        "Likewise, THE USER authorizes PET GOURMET to process their personal data to fulfill their obligations and commitments on this platform, being authorized to store, transfer them, nationally or internationally, assign or share them without prior written authorization from the USER. These data will be stored by PET GOURMET who declares the existence of a database and that it is duly protected under the appropriate security measures to protect the personal data provided.",
      authorizationsSecurity:
        "PET GOURMET will inform about violations of the security implemented for the processing of personal data. THE USER will have the right to revoke his consent and to exercise all the rights enshrined in the Organic Law on the Protection of Personal Data. Ten (10) years after obtaining the personal data, PET GOURMET undertakes to eliminate the personal data in a safe and systematic manner, without retaining any data in its favor for any specific purpose.",
      authorizationsUserData:
        "THE USER declares that the personal data provided is not erroneous or inaccurate, but if it is, it may be corrected at the USER's request. THE USER is guaranteed of the existence and way in which their rights of access, deletion, rectification and updating, opposition, cancellation, portability, limitation of treatment can be made effective and not to be subject to a decision based solely on automated evaluations, as well as the way to make claims before the person responsible for the processing of personal data and the Personal Data Protection Authority." +
        "THE USER declares that he has no obligations or exclusive rights with natural or legal persons that limit the exercise of these conditions, therefore they exempt him from any responsibility or claim of any third party for this fact.",
      authorizationsNameUser:
        "THE USER authorizes his name to be published in the PET GOURMET media.",
      accountsInfo:
        "Certain areas of the Platform may require registration or otherwise request, provide information to participate in certain features or access certain content. If you choose not to provide such information, you may not be able to access certain content or participate in certain features of the Platform or any features at all.",
      accountsContentInfo:
        "THE USER understands that certain offers, services and features that may be available on the Platform may be subject to additional specific terms and conditions. This may include loyalty and rewards programs. In the event of any conflict between this Agreement and any such specific terms and conditions, the specific terms and conditions will control. You also understand and agree that certain offers, services and features may be available on the web page version of the Platform and not on the mobile application version of the Platform, and vice versa.",
      accountsMoreContentInfo:
        "In addition, THE USER is solely responsible for any and all activities that occur under his account, including mobile payments, all transactions and other activities carried out with the device, or stored payment card, whether or not authorized. THE USER undertakes to notify PET GOURMET immediately of any unauthorized use of his account. In addition, you agree not to email, post or disseminate any user ID, password or other information that provides you with access to the Platform. PET GOURMET is not responsible for any loss you may suffer as a result of someone else using your password or account, either with or without your knowledge. PET GOURMET will not be responsible for any loss or theft of user information transmitted from or stored on a computer or device or for unauthorized or fraudulent transactions related to the Platform.",
      accountUserAccept:
        "The USER accepts that the providers of PET GOURMET and PET GOURMET may collect and use technical data and related information, including, but not limited to, technical information about your device, geolocation, date and time of the access platform, system and application software, and peripherals, which meets from time to time to, among other things, facilitate the provision of software updates, product support, and other services to you in connection with the Platform. THE USER grants PET GOURMET permission to use this information to improve its products or to provide you with services or technologies, as well as to provide advertising content in which PET GOURMET believes you might be interested, including working with third parties that provide targeted advertising content.",
      accountPlatform:
        "Through the platform, you may be able to order and/or pay PET GOURMET for products or services. In order to be able to order and pay for these types of products or services, you must provide certain information relevant to your transaction, including, without limitation, your credit or debit card number, the expiration date of your credit card, or debit card, the name of your credit card or debit card, and your billing address. You can also supply shipping information. You represent and warrant that you have the legal right to use any bank card or other payment method used in connection with any transaction. By submitting such information, you grant PET GOURMET and/or third parties the right to provide and transmit such information for the purpose of facilitating the completion of transactions initiated by you or on your behalf. Verification of information may be required prior to confirmation or completion of any transaction.",
      dutiesInfo:
        "Personal data will not be transferred to third parties, and PET GOURMET S.A.('PET GOURMET'), does not market, sell or rent its database to third parties.",
      dutiesContentInfo:
        "The User may freely request access, rectification, updating, inclusion or modification, cancellation and any other action related to their personal information in accordance with the Constitution of the Republic of Ecuador to PET GOURMET S.A. ('PET GOURMET').",
      dutiesMoreContentInfo:
        "PET GOURMET S.A.('PET GOURMET') retains the user's information for as long as the user's account is active. In the event that the User wishes to delete their account, they must follow the following process:",
      dutiesAccount:
        "• Send the account deletion request to PET GOURMET email \n" +
        "• The User will receive an email indicating that in a maximum period of 10 business days his account will be deleted. \n" +
        "• Before the established date, the User will receive an email in which all the data that has been deleted will be shown.",
      dutiesEliminatedData:
        "Once the procedure for deleting your data has been completed, PET GOURMET S.A. (“PET GOURMET”), will not have any record in the databases, so only the orders that the User has made will remain for the purposes of fraud prevention analysis and internal statistical data of the company.",
      dutiesSubtitle:
        "REPRESENTATION, DISCLAIMER OF WARRANTIES AND LIMITATIONS OF LIABILITY LINKS TO THIRD PARTY PLATFORMS AND SERVICES",
      dutiesPlatform:
        "The Platform may provide connectivity or links to other third party services, websites, applications, software and other content from third party providers such as social partners, the media, mobile service providers and third party software application developers ('Services of Third Parties'). The Platform may allow you to add/configure certain third party services on the device. The Platform may also allow you to log into your account through Facebook or another social media account. PET GOURMET has no control over this, makes no representations or warranties of any kind about any of the Third Party Services you access, is not responsible for the availability of such Third Party Services, and does not endorse or be responsible for them. any content or other materials available on such Third Party Services. Your use of third party services may be subject to additional terms, including software license terms, of such third parties.",
      dutiesServices:
        "If you use Third Party Services, you agree that you are aware that account and other personal information held by those third parties may be transmitted to PET GOURMET and stored on PET GOURMET's servers and/or applications located in the United States and in other places. You understand and agree that the companies that provide the third party services may access, use and share certain information about you. THE USER understands and accepts that PET GOURMET is not responsible for these companies, or the use of their information. Use of third party services is at your own risk.",
      dutiesContact:
        "In the event of a complaint or concern regarding this Agreement or the Platform; or for more information, contact PET GOURMET https://www.purenature-pets.com.",
      privacyInfo:
        "PET GOURMET S.A. (“PET GOURMET”) respects your right to privacy. This policy outlines what personal information we may collect, store and handle and other important issues related to your privacy and data protection." +
        "It is the policy of PET GOURMET S.A. (“PET GOURMET”) to comply with all current privacy and data protection laws. This commitment reflects the value we place on earning and keeping our customers' trust." +
        "This Policy applies to the Internet website www.purenature.com.ec; also to all personal information that PET GOURMET S.A. (“PET GOURMET”) may obtain in any other way: (1) through the purchase of our products." +
        "On the PET GOURMET website www.purenature.com.ec, according to the functionality that the client accesses on said website, the following information is collected:",
      privacyContentInfo:
        "• Location data will be shared at the time of ordering \n" +
        "• Personal data: RUC, company name, email and telephone number \n" +
        "• Access data: Username, password.",
      privacyMoreContentInfo:
        "PET GOURMET S.A.('PET GOURMET') retains the user's information for as long as the user's account is active. In the event that the User wishes to delete their account, they must follow the following process:",
      privacyMailInfo:
        "Send the account deletion request to PET GOURMET email \n" +
        "The User will receive an email indicating that in a maximum period of 10 business days his account will be deleted. \n" +
        "Before the established date, the User will receive an email in which all the data that has been deleted will be shown.",
      privacyDataInfo:
        "Once the data removal procedure has been completed, PET GOURMET S.A.('PET GOURMET'), will not have any record in the databases, so only the orders that the User has made will remain for the purposes of analysis of prevention of fraud and internal statistical data of the company.",
      protectedData: "Protection and use of personal data"
    },
    profileSupport: {
      title: "Support",
      subTitle: "Contact us",
      email: "Email"
    },
    profileQuestions: {
      title: "Frequently asked questions",
      question1: "Are the prices of the products include VAT?",
      answer1:
        "Pet care products and snacks include VAT. Pet food products do not charge VAT.",
      question2: "Is it safe to make the payment in our online store?",
      answer2:
        "Absolutely. We have advanced security measures to protect your data and guarantee secure transactions. You can buy with confidence!",
      question3: "What is the estimated delivery time?",
      answer3:
        "The delivery time will be around 72 hours. We will be contacting you to inform you in case there is any news with the dispatch.",
      question4: "How do I track my order?",
      answer4:
        "After making your purchase, TRAMACO (our order distribution company), will contact you to deliver your order.",
      question5: "Can I cancel my order?",
      answer5:
        "You can contact us at the following email: atencion.cliente@petgourmet.com.ec"
    }
  },
  cart: {
    title: "Cart",
    subtotal: "Subtotal",
    total: "Total",
    product: "Product",
    taxes: "Taxes",
    add: "Add to cart",
    added: "These are the products you have added...",
    youOrder: "You order",
    emptyTotal: "$0.00",
    emptyCart: "Empty cart",
    continueAdd: "Continue adding",
    minimumAmountNotReached:
      "The minimum amount of your order must be at least",
    keepAddingProducts: "Continue adding to proceed with the purchase"
  },
  checkout: {
    title: "Checkout",
    personalInfo: "Personal information",
    paymentMethod: "Payment method",
    cashPayment: "Cash payment",
    cardPayment: "Card payment",
    cash: "Cash",
    card: "Card",
    status: {
      error: {
        text: "There was a problem verifying the payment",
        tryAgain: "Try again"
      },
      empty: {
        title: "You have not added products to your cart yet",
        description: "Explore our menu and add products!",
        buttonTitle: "Explore Menu"
      },
      success: "Your payment has been processed successfully",
      loading: {
        processing: "We are processing your payment...",
        creating: "We are creating the user",
        savingAddress: "We are saving the delivery address",
        savingBilling: "We are saving billing data"
      }
    },
    anonymousTitle: "Don't miss the discounts!",
    signIn: "Sign in now",
    deliveryAddress: "Delivery address",
    payExactValue: "pay with the exact value",
    howMuchPay: "How much will you pay?",
    enoughAmount: "The amount is not enough.",
    change: "Change",
    note: "Add a note for this order",
    writeMessage:
      "Delivery instructions, person who receives it, schedules, etc.",
    billing: {
      title: "Billing data",
      subTitle: "billing information",
      addData: "Add new billing information",
      billingExceded: "Billing data limit exceeded",
      onlyPossible: "It is only possible to have",
      active: "active billing data"
    }
  },
  pickUpWorkflow: {
    store: "Store"
  },
  loyalty: {
    metaTitle: "Loyalty"
  },
  auth: {
    signInShort: "Sign in",
    signIn: "Sign in or access with your social media.",
    signUp: "Sign up",
    alternativeSignIn: "Need a new account?",
    accountQuestion: "Already have an account?",
    accountQuestionModal: "Are you new?",
    signInConfirmation: {
      title: {
        loading: "Signing in...",
        success: "Sign in successfully",
        error: "Something went wrong trying to sign in"
      }
    },
    signInRUC: {
      join: "Register your company",
      enterRUC: "Enter your RUC",
      RUC: "RUC",
      businessName: "Business name"
    },
    signInForm: {
      emailQuestion: "Or using your email",
      forgotPasswordQuestion: "Did you forget your password?",
      forgotPassword: "Forgot my password",
      recoverPassword: "Recover password"
    },
    signUpRUC: {
      enterRUC: "Register your company with your RUC"
    },
    signUpUser: {
      hello: "Hello!",
      createAccount: "Create your account",
      oneMoreStep: "One more step",
      acceptTerms: "I accept the ",
      terms: "terms and conditions",
      privacy: "conditions",
      infoTitle: "Please note the following",
      infoDescription:
        "If you continue you will lose the data associated with your identifier",
      errorMessage: "A problem occurred, please try again",
      fieldsError: "Please fill in all the fields",
      acceptEmail: "I agree to receive e-mails",
      requiredTerms: "You must accept the terms and conditions",
      and: " y ",
      acceptPrivacyPolicies: "I have read and accept the privacy policies",
      configPrivacyPolicies: " configure preferences",
      consent: "I consent to the processing of my data for:",
      infoPrivacyPolicies:
        "You may withdraw your consent and exercise any of your rights by following the instructions in the policies. For any questions or concerns you can contact the following email: privacidad.datos@purenature.com",
      privacyPolicy: "privacy policy",
      privacyPolicyDescription:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempora odio odit dolores? Explicabo ex autem vitae, consequuntur et alias, obcaecati rerum repudiandae assumenda deleniti culpa totam tempore mollitia nulla architecto. Lorem ipsum dolor sit amet consectetur      adipisicing elit. Officiis dolor quod libero soluta unde asperiores      vero ducimus natus impedit nulla, autem rem recusandae commodi      doloribus hic dolorem rerum totam dolore! Lorem ipsum dolor sit amet      consectetur adipisicing elit. Consequuntur eligendi fuga aliquid      aperiam repudiandae, eius ipsa nihil fugiat reiciendis recusandae      blanditiis totam nobis dolore aspernatur cumque mollitia amet laborum      neque?"
    },
    passwordStrength: {
      mustContain: "The password must contain:",
      capitalLetter: "At least one capital letter",
      eightCharacters: "At least 8 characters",
      oneNumber: "At least one number",
      specialCharacter: "At least one special character"
    },
    singleSignOn: {
      selectRegister: "Select how you want to register",
      selectLogin:
        "Enjoy the benefits of our exclusive deals by logging in with your account",
      signInGoogle: "Sign in with Google",
      signInFacebook: "Sign in with Facebook",
      signInMagicLink: "Access with email"
    },
    signOptions: {
      trackOrder: "Track your orders",
      createAccountQuestion: "Do not you have an account yet?",
      createAccount: "Create account",
      anonymousLogin: "Sign in with your account now",
      continueAsGuest: "Continue as guest",
      continueAsGuestDescription: "Don't want to complete your data?"
    },
    recover: {
      enterEmail: "Enter your email",
      sendLink: "We will send a link so you can log in.",
      resend: "Resend",
      resendEmail: "Resend email",
      wrongEmail: "Did you get the wrong email?",
      editEmail: "Edit email",
      sendEmail: "We have sent an email to:",
      changePassword:
        "There you will find a link that will allow you to change your password",
      recoveryEmail: "Haven't received the recovery email?",
      recoverTimer: "Try again on ",
      recoverSpamCheck: "If you don't see it, check your spam folder."
    },
    magic: {
      sendEmail: "We have sent an email to:",
      info: "There you will find a link that will allow you to access your account.",
      enterEmail: "Enter your data",
      receivedEmail: "Haven't received the mail?",
      sendLink: "We will send a link so you can log in.",
      resendEmail: "Resend email",
      spamCheck: "If you don't see it, check your spam folder."
    }
  },
  selectAddress: {
    delivery: "Deliver en",
    pickUp: "Pick up at",
    address: "Select an address",
    store: "Select a store",
    location: "Use my current location",
    nearestStore: "Select nearest store",
    errorUpdateAddress: "An error occurred while trying to update address data",
    fillAddress: "Fill your address",
    noCoverage: "Your address is out of coverage, try changing it",
    currentPosition: "Your current position",
    editLocation: "Edit Location",
    deliveryAddress: "Delivery Address",
    locate: "Locate me on the map",
    empty: "No results found",
    findLocation: "Find your address",
    caveat1:
      "Please check if your address is located within a parish where there is no coverage to ship your order. You can confirm by making a ",
    caveatClick: "clicking here",
    caveat2:
      ". If you have questions about coverage, please contact the support team.",
    province: "Province",
    canton: "Canton",
    parish: "Parish",
    selectProvince: "Select your province",
    selectCanton: "Select your canton",
    selectParish: "Select your parish"
  },
  repeatOrder: {
    lastOrder: "Repeat last order",
    order: "Order"
  },
  club: {
    join: "Join our",
    club: "Pure Nature Club"
  },
  product: {
    about: "About this item",
    recommended: "Recommended for you",
    promotions: "Promotions",
    purchaseWithPoints: "Purchase this product with your points",
    availablePoints: "Available points",
    limit: "If you want more than the limit of units, please contact the",
    shareProduct: "Share this product",
    shareWithAnother: "Share this product with another person",
    support: "Support team"
  },
  modifiers: {
    errorMin: "Choose at least",
    errorMax: "Choose maximum",
    options: "options",
    description: "Select minimum 12 units",
    required: "Required",
    optional: "Optional",
    perUnit: "p/u"
  },
  notFound: {
    title: "Page not found",
    description:
      "The page you are looking for does not exist or has been removed.",
    back: "Back to home"
  },
  searchBar: {
    recentSearch: "Recent Search",
    cleanHistory: "Clean History"
  },
  clubBenefits: {
    title: "Enjoy our",
    benefits: "benefits",
    subtitle: "Confirm the following information and you're done!",
    businessType: "Business type",
    joinClub: "Join the Pure Nature Club"
  },
  privacyModal: {
    title: "We invite you to review the new policies and accept them.",
    paragraph1:
      "We use the information you provide to manage our business initiatives and maintain a close relationship with our customers.",
    paragraph2:
      "We want you to know that you have the right to access, update, revoke, delete and oppose the processing of your data. If you wish to exercise any of these rights, please do not hesitate to send us an email to: atencion.cliente@petgourmet.com.ec We will be happy to help you with whatever you need.",
    accept: "Accept"
  },
  deleteAccount: {
    errorDeleted: "Account could not be deleted",
    title: "Delete account",
    question: "Are you sure you want to delete your account?",
    description:
      "Once you delete your account you will not be able to recover it and you will have to create a new account.",
    cancel: "Maintain my account",
    delete: "Delete my data"
  },
  notificationModal: {
    title: "You are out of coverage",
    description: "Try changing your location to continue",
    changeLocation: "Change location",
    change: "Change"
  },
  cvvValidation: {
    title: "For security reasons",
    description: "re-enter the CVV code",
    validatePay: "Validate and pay"
  },
  outService: {
    title: "We will be back with everything your business deserves.",
    description: ""
  }
};

export default en;
