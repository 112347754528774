import { Modal, Image } from "artisn-ui-react";
import CloseSVG from "images/close.svg";
import React, { cloneElement } from "react";

import Button from "../Button/Button";
import Styles from "./InfoActionModal.styles";
import { InfoActionModalProps as Props } from "./InfoActionModal.types";
import useI18n from "hooks/useI18n";

const InfoActionModal: React.FC<Props> = props => {
  const { className, icon, title, description, opened } = props;
  const { cancelAction, confirmAction, cancelText, confirmText } = props;
  const { onClose } = props;
  const t = useI18n();

  if (!cancelAction && !confirmAction) {
    throw new Error(
      `${InfoActionModal.name}: You must provide at least one action for this modal`
    );
  }

  const renderIcon = () => {
    if (!icon) return null;
    if (typeof icon === "string") {
      return (
        <Image
          className="InfoActionModal__img"
          image={icon}
          alt="Action icon"
          placeholder="blur"
        />
      );
    }
    return cloneElement(icon, {
      className: "InfoActionModal__img"
    });
  };

  return (
    <Modal
      className="InfoActionModal__modal"
      opened={opened}
      closeIcon={cancelAction ? <CloseSVG /> : undefined}
      onClose={onClose}
    >
      <Styles className={`InfoActionModal ${className}`} hasIcon={!!icon}>
        {renderIcon()}
        <h3 className="InfoActionModal__title">{title}</h3>
        {description ? (
          <p className="InfoActionModal__description">{description}</p>
        ) : null}
        <div className="InfoActionModal__buttons">
          {cancelAction ? (
            <Button
              className="InfoActionModal__button InfoActionModal__cancel-button"
              color="primary-light"
              type="BORDER"
              onClick={cancelAction}
            >
              {cancelText ?? t.common.cancel}
            </Button>
          ) : null}
          {confirmAction ? (
            <Button
              className="InfoActionModal__button InfoActionModal__confirm-button"
              color="primary"
              onClick={confirmAction}
            >
              {confirmText ?? t.common.confirm}
            </Button>
          ) : null}
        </div>
      </Styles>
    </Modal>
  );
};

InfoActionModal.defaultProps = {
  className: ""
};

export default InfoActionModal;
