import InfoCircleSVG from "images/info.svg";
import React, { useState } from "react";

import Styles from "./ShoppingCartProduct.styles";
import { ShoppingCartProductProps as Props } from "./ShoppingCartProduct.types";
import Counter from "components/global/Counter/Counter";
import HorizontalProductCard from "components/global/HorizontalProductCard/HorizontalProductCard";

// import DeleteSVG from "images/delete.svg";

const ShoppingCartProduct: React.FC<Props> = props => {
  const {
    product,
    initialQuantity = 0,
    onChangeQuantity,
    onDelete,
    disabled,
    onClick,
    modifiers,
    minCounter = 1,
    maxCounter,
    onAdd,
    onSubtract,
    amountTotal,
    hideEdit,
    ...rest
  } = props;
  const { available, type } = product ?? {};
  //@ts-ignore
  const productCombo = type === "COMBO";
  const [count, setCount] = useState(minCounter);
  const onChange = (value: number) => {
    setCount(value);
    onChangeQuantity(value);
  };

  return (
    <Styles className="ShoppingCartProduct" {...rest} count={count}>
      <HorizontalProductCard
        product={product}
        modifiers={modifiers}
        fromShoppingCart
      />
      <div className="ShoppingCartProduct__modifyProduct">
        {onDelete ? (
          <button className="ShoppingCartProduct__button" onClick={onDelete}>
            Eliminar
          </button>
        ) : null}
        {onClick && !hideEdit ? (
          <button className="ShoppingCartProduct__button" onClick={onClick}>
            Editar
          </button>
        ) : null}
        {!available ? (
          <p className="ShoppingCartProduct__error">
            <InfoCircleSVG viewBox="0 0 19 20" />
            Este producto no está disponible
          </p>
        ) : null}
        <Counter
          className="ShoppingCartProduct__counter"
          initialValue={productCombo ? 0 : initialQuantity}
          onChange={!productCombo ? onChange : undefined}
          onAdd={!productCombo ? onAdd : undefined}
          onSubtract={onSubtract}
          disabled={disabled || productCombo}
          min={minCounter}
          max={maxCounter}
          value={amountTotal && productCombo ? amountTotal : undefined}
        />
      </div>
    </Styles>
  );
};

ShoppingCartProduct.defaultProps = {};

export default ShoppingCartProduct;
