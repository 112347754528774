import { useGeo } from "@simple/contexts";
import { usePutShippingAddress } from "@simple/services";
import { useFetchCoordsFromGoogleAddress } from "@simple/services";
import { useFetchGoogleAddressFromCoords } from "@simple/services";
import { useFetchNearbyStores } from "@simple/services";
import { StaticMap, Clickable, Coordinates } from "artisn-ui-react";
import { ShippingAddress } from "artisn/types";
import { Coords } from "google-map-react";
import ChevronLeftSVG from "images/arrow-to-left.svg";
import CloseSVG from "images/close.svg";
import MapSVG from "images/geo.svg";
import EmptySVG from "images/no-location.svg";
import React, { useEffect, useRef, useState } from "react";

import { AddressFormValues } from "../AddressForm/AddressForm.types";
import AddressList from "../AddressList/AddressList";
import Styles from "./AddressFormModal.styles";
import { AddressFormModalProps as Props } from "./AddressFormModal.types";
import { SelectObject } from "./AddressFormModal.types";
import ChooseAddressInMap from "components/chooseAddressInMap/ChooseAddressInMap/ChooseAddressInMap";
import Button from "components/global/Button/Button";
import Divider from "components/global/Divider/Divider";
import Modal from "components/global/Modal/Modal";
import SearchAddress from "components/global/SearchAddress/SearchAddress";
import AddressForm from "components/profileAddresses/AddressForm/AddressForm";
import MapAddressPreview from "components/profileAddresses/MapAddressPreview/MapAddressPreview";
import { getENVs } from "config/artisn.config";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useI18n from "hooks/useI18n";
import useWindowSize from "hooks/useWindowSize";
import { Google } from "types/geo.types";
import { defaultFunction, getMapSize } from "utils/common.utils";
import { trimFields } from "utils/form.utils";
import { dismissErrorNotification } from "utils/notifications.utils";
import { createErrorNotification } from "utils/notifications.utils";

const { DELIVERY_URL } = CONSTANTS.EXTERNAL_URLS;

const AddressFormModal: React.FC<Props> = props => {
  const { opened, editAddress, locationOnly, onDismiss } = props;
  const { onClose = defaultFunction } = props;
  const auth = useAuth();
  const { mutate: putMutate, reset: putReset } = usePutShippingAddress(auth);
  const [predictedPlaces, setPredictedPlaces] =
    useState<Google.Autocomplete[]>();
  const containerRef = useRef<any>();
  const [initialValues, setInitialValues] = useState<AddressFormValues>();
  const [mapAddress, setMapAddress] = useState<Google.Geocode>();
  const { geometry } = mapAddress! ?? {};
  const { location } = geometry ?? {};
  const [coordinates, setCoordinates] = useState<Coordinates>(location);
  const [formattedAddress, setFormattedAddress] = useState("");
  const { isAnonymous } = auth;
  const [step, setStep] = useState(isAnonymous ? 3 : 1);
  const [prevStep, setPrevStep] = useState(isAnonymous ? 3 : 1);
  const { width, height } = useWindowSize();
  const { height: mapHeight, width: mapWidth } = getMapSize(width, height);
  const { selectedCoordinates, deviceCoordinates } = useGeo();
  const [selectedPlaceName, setSelectedPlaceName] = useState("");
  const { data: place } = useFetchCoordsFromGoogleAddress(
    getENVs?.apiKey ?? "",
    selectedPlaceName
  );
  const [result, setResult] = useState("");
  const t = useI18n();
  const { data: googleAddressFromCoords } = useFetchGoogleAddressFromCoords(
    `${getENVs?.apiKey}`,
    selectedCoordinates
  );
  const { setSelectedCoordinates } = useGeo();
  const { data: stores } = useFetchNearbyStores(
    coordinates ?? selectedCoordinates
  );
  const noCoverage = !stores?.length;

  const handlerMapSearch = () => {
    setStep(3);
    setPrevStep(1);
  };

  const closeHandler = () => {
    onClose();
    clearModal();
  };

  const selectedCoordinatesHandler = (coordinates: Coords) => {
    setSelectedCoordinates(coordinates);
    setCoordinates(coordinates);
    setStep(2);
    onDismiss?.();
    if (locationOnly) {
      onClose();
      clearModal();
    }
  };

  const addressInMapHandler = (address: Google.Geocode) => {
    setMapAddress(address);
    setStep(2);
  };

  const addressInMapGoBackHandler = () => {
    if (prevStep === 1) setStep(1);
    if (prevStep === 2) setStep(2);
  };

  const addressFormGoBackHandler = () => {
    setSelectedPlaceName("");
    if (isAnonymous) setStep(3);
    else setStep(1);
  };

  const editAddressInMapHandler = () => {
    if (mapAddress) {
      setCoordinates(location);
      setPrevStep(2);
      setStep(3);
      return;
    }
    if (editAddress) {
      const { lat, lng } = editAddress;
      setCoordinates({ lat, lng });
      setPrevStep(2);
      setStep(3);
    }
  };

  const onEditAddress = (address: ShippingAddress) => {
    const enhancedAddress: ShippingAddress = {
      ...address,
      default: true
    };
    const newAddress = trimFields(enhancedAddress);
    //@ts-ignore
    putMutate(newAddress, {
      onError: () => {
        dismissErrorNotification();
        createErrorNotification(t.selectAddress.errorUpdateAddress);
        console.error("An error occurred in the POST request");
      },
      onSuccess: () => {
        onClose();
        clearModal();
        putReset();
      }
    });
  };

  const clearModal = () => {
    setStep(isAnonymous ? 3 : 1);
    setPrevStep(1);
    setInitialValues(undefined);
    setSelectedPlaceName("");
    setResult("");
    setPredictedPlaces(undefined);
  };

  const handleCaveat = () => {
    window.open(DELIVERY_URL, "_blank");
  };

  useEffect(() => {
    if (opened && deviceCoordinates && !mapAddress && !editAddress) {
      setCoordinates(deviceCoordinates);
    }
  }, [opened, deviceCoordinates, isAnonymous, mapAddress, editAddress]);

  useEffect(() => {
    if (!editAddress) return;
    const { lat, lng, livingPlace } = editAddress ?? {};
    const { fields } = livingPlace;
    const { label } = fields[0];
    //FIXME: flushSynC is drawing another coordinates
    setCoordinates({ lat, lng });
    setFormattedAddress(label);
    setInitialValues({
      ...editAddress
    });
    setStep(2);
    setPrevStep(2);
  }, [editAddress]);

  useEffect(() => {
    if (!mapAddress) return;
    const { geometry, formatted_address } = mapAddress;
    const { location } = geometry;
    setCoordinates(location);
    setFormattedAddress(formatted_address);
  }, [mapAddress]);

  useEffect(() => {
    if (!place) return;
    setMapAddress(place);
    setStep(2);
    setPrevStep(1);
  }, [place]);

  useEffect(() => {
    const [place] = googleAddressFromCoords ?? [];
    if (!place || !isAnonymous) return;
    setMapAddress(place);
    if (opened) return;
    setStep(3);
    setPrevStep(1);
  }, [googleAddressFromCoords, isAnonymous, opened]);

  const addressFormHeader = (
    <div className="ModalWrapper__column">
      <div className="ModalHeader">
        {!editAddress ? (
          <Clickable
            className="ModalHeader__icon"
            onClick={addressFormGoBackHandler}
          >
            <ChevronLeftSVG />
          </Clickable>
        ) : null}
        <h3 className="ModalHeader__title">{t.selectAddress.fillAddress}</h3>
        <Clickable onClick={closeHandler} className="ModalHeader__icon">
          <CloseSVG />
        </Clickable>
      </div>
    </div>
  );

  const addressFormNode = (
    <div className="AddressFormModal__address-form" ref={containerRef}>
      <StaticMap
        googleMapsKey={`${getENVs?.mapsApiKey}`}
        width={containerRef?.current?.offsetWidth - 32 || mapWidth}
        height={mapHeight}
        className="AddressFormModal__static-map"
        markers={[
          {
            coordinates,
            icon: "https://res.cloudinary.com/dphyzfzpu/image/upload/v1629756397/Simple/Map-point_Copy_5_mol5yb.png"
          }
        ]}
      />
      {noCoverage ? (
        <p className="AddressFormModal__coverage">
          {t.selectAddress.noCoverage}
        </p>
      ) : null}
      <div className="AddressFormModal__header">
        <div className="AddressFormModal__info">
          <p className="AddressFormModal__info__title">
            {t.selectAddress.currentPosition}
          </p>
          <p className="AddressFormModal__info__position">{formattedAddress}</p>
        </div>
        <Button
          type="BORDER"
          color="primary"
          className="AddressFormModal__header__button"
          onClick={editAddressInMapHandler}
        >
          {t.selectAddress.editLocation}
        </Button>
      </div>
      <div className="AddressFormModal__caveat">
        <p className="AddressFormModal__caveat__title">
          {t.selectAddress.caveat1}{" "}
          <span
            className="AddressFormModal__caveat__link"
            onClick={() => handleCaveat()}
          >
            {t.selectAddress.caveatClick}
          </span>
          {t.selectAddress.caveat2}
        </p>
      </div>
      <AddressForm
        mapAddress={mapAddress!}
        method={editAddress ? "PUT" : "POST"}
        initialValues={initialValues}
        onPressButton={() => {
          onClose();
          clearModal();
        }}
        disabled={noCoverage}
        editAddress={editAddress}
      />
    </div>
  );

  const mapAddressPreviewHeader = (
    <div className="ModalWrapper__column">
      <div className="ModalHeader">
        <h3 className="ModalHeader__title">{t.common.deliveryAddress}</h3>
        <Clickable onClick={closeHandler} className="ModalHeader__icon">
          <CloseSVG />
        </Clickable>
      </div>
      <SearchAddress
        onPredictedPlaces={setPredictedPlaces}
        showSearchDropdown={false}
        onInputChange={() => setResult("")}
      />
      <Divider className="ModalHeader__divider" />
      <Button
        className="ModalHeader__locate"
        type="BORDER"
        onClick={handlerMapSearch}
      >
        <MapSVG /> {t.selectAddress.locate}
      </Button>
    </div>
  );
  const mapAddressPreviewNode = (
    <div>
      {predictedPlaces?.length === 0 ? (
        <div className="AddressFormModal__empty">
          <div className="AddressFormModal__empty__icon">
            <EmptySVG />
          </div>
          <div className="AddressFormModal__empty__title">
            {t.selectAddress.empty}
          </div>
        </div>
      ) : null}
      {predictedPlaces?.length || result ? (
        <AddressList
          className="AddressFormModal__address-list"
          searchResult={result || predictedPlaces}
          onEdit={onEditAddress}
          dropdownFixed={false}
        />
      ) : null}
      {predictedPlaces?.map((item, index) => {
        const { description } = item;
        return (
          <MapAddressPreview
            key={index}
            address={description}
            onClick={() => setSelectedPlaceName(description)}
            className="AddressFormModal__map-address-preview"
          />
        );
      })}
      {result ? (
        <MapAddressPreview
          address={result}
          onClick={() => setSelectedPlaceName(result)}
          className="AddressFormModal__map-address-preview"
        />
      ) : null}
    </div>
  );

  const chooseAddressInMapHeader = (
    <div className="ModalWrapper__column">
      <div className="ModalHeader">
        {!isAnonymous ? (
          <Clickable
            className="ModalHeader__icon"
            onClick={addressInMapGoBackHandler}
          >
            <ChevronLeftSVG />
          </Clickable>
        ) : null}
        <h3 className="ModalHeader__title">{t.selectAddress.findLocation}</h3>
        <Clickable onClick={closeHandler} className="ModalHeader__icon">
          <CloseSVG />
        </Clickable>
      </div>
    </div>
  );

  const chooseAddressInMapNode = (
    <ChooseAddressInMap
      onSelectedAddress={addressInMapHandler}
      initialCoordinates={coordinates}
      onSelectedCoordinates={selectedCoordinatesHandler}
    />
  );

  const selectHeader: SelectObject = {
    1: mapAddressPreviewHeader,
    2: addressFormHeader,
    3: chooseAddressInMapHeader
  };

  const selectContent: SelectObject = {
    1: mapAddressPreviewNode,
    2: addressFormNode,
    3: chooseAddressInMapNode
  };

  return (
    <Modal
      {...props}
      closeOnClickOutside={false}
      opened={opened}
      onClose={() => {
        onClose();
        clearModal();
      }}
      header={selectHeader[step]}
    >
      <Styles className="AddressFormModal" step={step}>
        {selectContent[step]}
      </Styles>
    </Modal>
  );
};

AddressFormModal.defaultProps = {};

export default AddressFormModal;
