import { useFetchShippingAddresses } from "@simple/services";
import LocationSVG from "images/empty-data.svg";
import React from "react";

import ChooseAddress from "../ChooseAddress/ChooseAddress";
import { getMatchingAddresses } from "./AddressList.helpers";
import Styles from "./AddressList.styles";
import { AddressListProps as Props } from "./AddressList.types";
import CommonPlaceholder from "components/global/CommonPlaceholder/CommonPlaceholder";
import EmptyState from "components/global/EmptyState/EmptyState";
import ErrorBlock from "components/global/ErrorBlock/ErrorBlock";
import useAuth from "contexts/auth/auth.context.hooks";
import useI18n from "hooks/useI18n";

const AddressList: React.FC<Props> = props => {
  const { actions, className, onEdit, onSelect, searchResult } = props;
  const { dropdownFixed } = props;
  const auth = useAuth();
  const { uid } = auth;
  const {
    data: addressList,
    isLoading,
    isError
  } = useFetchShippingAddresses(auth);
  const t = useI18n().profile.profileAddress.empty;
  const empty = addressList && addressList.length === 0;

  const placeholdersNode = (
    <>
      <CommonPlaceholder />
      <CommonPlaceholder />
      <CommonPlaceholder />
      <CommonPlaceholder />
      <CommonPlaceholder />
    </>
  );

  const emptyNode = (
    <EmptyState
      icon={<LocationSVG />}
      title={t.title}
      description={t.description}
    />
  );

  const addressListNode = () => {
    if (!searchResult?.length) {
      return addressList?.map(address => (
        <div key={address.id} className="AddressList__item">
          <ChooseAddress
            shippingAddress={address}
            actions={actions}
            onEdit={onEdit}
            onClick={onSelect}
            dropdownFixed={dropdownFixed}
          />
        </div>
      ));
    }
    return addressList
      ?.filter(address => getMatchingAddresses(address, searchResult))
      .map(address => (
        <div key={address.id} className="AddressList__item">
          <ChooseAddress
            shippingAddress={address}
            actions={actions}
            onClick={onSelect ?? onEdit}
            dropdownFixed={dropdownFixed}
          />
        </div>
      ));
  };

  return (
    <Styles className={`AddressList ${className ?? ""}`}>
      {isLoading ? placeholdersNode : null}
      {empty && !searchResult ? emptyNode : null}
      {isError ? <ErrorBlock queryKey={[uid, "addresses"]} /> : null}
      {!isLoading && !empty ? addressListNode() : null}
    </Styles>
  );
};

AddressList.defaultProps = {};

export default AddressList;
