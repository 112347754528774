import { useGeo } from "@simple/contexts";
import { useCountries } from "@simple/contexts";
import { Slider } from "artisn-ui-react";
import { events } from "artisn/analytics";
import GoogleMapReact, { ChangeEventValue } from "google-map-react";
import GeoSVG from "images/geo.svg";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useCallback } from "react";
import { flushSync } from "react-dom";

import Button from "../Button/Button";
import StorePreview from "../StorePreview/StorePreview";
import { mapOptions, mapStyles } from "./Map.helpers";
import Styles from "./Map.styles";
import { MapProps as Props, MapsApiObject } from "./Map.types";
import Marker from "./Marker/Marker";
import PinMarker from "./PinMarker/PinMarker";
import StoreMarker from "./StoreMarker/StoreMarker";
import { getENVs } from "config/artisn.config";
import useAnalytics from "contexts/analytics/analytics.context.hooks";
import useAuth from "contexts/auth/auth.context.hooks";
import variables from "styles/util/variables";
import { getCoverageAreasFromStores } from "utils/geo.utils";

const { logOutOfCoverage } = events.geo;

const Map: React.FC<Props> = props => {
  const { selectedCountry } = useCountries();
  const { lat, lng } = selectedCountry;
  const { bounds: countryBounds } = selectedCountry;
  const { deviceCoordinates } = useGeo();
  const { isAnonymous } = useAuth();
  const { mapStore, address, noCoverage, className, isLoading } = props;
  const defaultCoords = useMemo(() => ({ lat, lng }), [lat, lng]);
  const { defaultCenter = defaultCoords, onStoreInfoClick } = props;
  const { center: newCenter, defaultZoom, isPinView, stores } = props;
  const { onConfirmClick, onChange, onStoreChange, onStoreClick } = props;
  const { showCoverage } = props;
  const { analyticsInitialized } = useAnalytics();
  const [center, setCenter] = useState(newCenter);
  const [mapsApi, setMapsApi] = useState<any>();
  const [map, setMap] = useState<any>();
  const outOfCoverageClassName = noCoverage ? "Map__out-of-coverage" : "";
  const polygonRef = useRef<any>([]);

  const apiHasLoaded = (mapsApiObject: MapsApiObject) => {
    const { maps, map } = mapsApiObject;

    setMapsApi(maps);
    setMap(map);
    if (showCoverage) map.setOptions({ styles: mapStyles });
  };

  const resetPolygons = useCallback(() => {
    let polygonRefs = polygonRef.current;

    for (const polygonRef of polygonRefs) {
      polygonRef.setMap(null);
    }
    polygonRefs = [];
  }, []);

  const setPolygonsInMap = useCallback(() => {
    if (!map || !mapsApi) return;
    const polygonRefs = polygonRef.current;

    const areasObject = getCoverageAreasFromStores(stores);
    for (const area of Object.values(areasObject)) {
      const polygon = new mapsApi.Polygon({
        paths: area,
        strokeOpacity: 0,
        fillColor: variables.palette["primary-light"],
        fillOpacity: 0.5,
        clickable: false
      });
      polygon.setMap(map);
      polygonRefs.push(polygon);
    }
  }, [map, mapsApi, stores]);

  const changeHandler = (mapValues: ChangeEventValue) => {
    if (!mapsApi) return;
    const { center: mapCenter } = mapValues;
    flushSync(() => {
      setCenter(mapCenter);
    });
    const bounds = new mapsApi.LatLngBounds(...countryBounds);
    if (bounds.contains(mapCenter)) {
      onChange?.(mapCenter);
      return;
    }
    setCenter(newCenter);
    onChange?.(newCenter);
  };

  const onClickMyLocation = () => {
    if (!deviceCoordinates) return;
    setCenter(deviceCoordinates);
    onChange?.(deviceCoordinates);
  };

  useEffect(() => {
    onStoreChange?.(mapStore);
    if (!mapStore) return;
    const { latitude, longitude } = mapStore;
    setCenter({ lat: latitude, lng: longitude });
  }, [onStoreChange, mapStore, setCenter]);

  useEffect(() => {
    if (!mapsApi) return;
    setCenter(newCenter);
  }, [mapsApi, newCenter]);

  useEffect(() => {
    if (noCoverage && analyticsInitialized) {
      logOutOfCoverage({
        userType: isAnonymous ? "anonymous" : "registered"
      });
    }
  }, [analyticsInitialized, noCoverage, isAnonymous]);

  useEffect(() => {
    if (!showCoverage) return;
    resetPolygons();
    setPolygonsInMap();
  }, [showCoverage, setPolygonsInMap, resetPolygons]);

  return (
    <Styles className={`Map ${className}`}>
      {isPinView ? <PinMarker /> : null}
      {address ? (
        <div className={`Map__store ${outOfCoverageClassName}`}>
          <p className="Map__store__address">{address.toLowerCase()}</p>
          {noCoverage ? (
            <p className="Map__store__address Map__no-coverage">
              Fuera de cobertura
            </p>
          ) : null}
        </div>
      ) : null}
      <GoogleMapReact
        defaultZoom={defaultZoom}
        defaultCenter={defaultCenter}
        bootstrapURLKeys={{ key: `${getENVs?.mapsApiKey}` }}
        options={mapOptions}
        center={center}
        onChange={changeHandler}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={apiHasLoaded}
      >
        {!isPinView
          ? stores?.map(store => {
              const { latitude, longitude, storeId } = store;
              const isActive = mapStore?.storeId === storeId;

              return (
                <Marker
                  key={storeId}
                  lat={latitude}
                  lng={longitude}
                  icon={
                    <StoreMarker
                      isActive={isActive}
                      onClick={() => onStoreClick?.(store)}
                    />
                  }
                />
              );
            })
          : null}
      </GoogleMapReact>
      <div className="Map__controls">
        <Button
          onClick={onClickMyLocation}
          className="Map__location-icon-container"
          type="LINK"
        >
          <GeoSVG />
        </Button>
        {isPinView ? (
          <Button
            onClick={() => onConfirmClick?.(center)}
            className="Map__confirm-button"
            disabled={(!newCenter || noCoverage || !address) && !isLoading}
            isLoading={isLoading}
          >
            Confirmar ubicación
          </Button>
        ) : (
          <Slider className="Map__slider" slideWidth={300} height={132} gap={8}>
            {stores?.map(store => {
              const { storeId } = store;
              const isSelected = mapStore?.storeId === storeId;

              return (
                <StorePreview
                  key={storeId}
                  isSelected={isSelected}
                  store={store}
                  onContentClick={() => onStoreClick?.(store)}
                  onInfoClick={() => onStoreInfoClick?.(store)}
                />
              );
            })}
          </Slider>
        )}
      </div>
    </Styles>
  );
};

Map.defaultProps = {
  className: ""
};

export default Map;
