import PinSVG from "images/pin.svg";
import React from "react";

import Styles from "./Marker.styles";
import { MarkerProps as Props } from "./Marker.types";

const Marker: React.FC<Props> = props => {
  const { icon } = props;
  const localIcon = icon ?? <PinSVG />;

  return <Styles className="Marker">{localIcon}</Styles>;
};

export default Marker;
