// Auth context
import { Storage } from "@capacitor/storage";
import { updateUser } from "@simple/services";
import firebase from "firebase";
import React, { createContext, FC, useCallback, useMemo } from "react";
import { useEffect, useState } from "react";

import { AuthProviderProps as Props } from "./auth.context.types";
import { AuthProviderValue } from "./auth.context.types";
import { auth, Services } from "config/artisn.config";
import CONSTANTS from "config/constants";
import useI18n from "hooks/useI18n";
import { getFirebaseAuthErrorMessage } from "utils/common.utils";

const { FACEBOOK_DATA } = CONSTANTS.STORAGE;

// @ts-ignore
export const AuthContext = createContext<AuthProviderValue>({});

const AuthProvider: FC<Props> = props => {
  const [openSignInModal, setOpenSignInModal] = useState<boolean>(false);
  const [isAnonymous, setIsAnonymous] = useState<boolean>();
  const [uid, setUid] = useState("");
  const [providerData, setProviderData] = useState<
    (firebase.UserInfo | null)[]
  >([]);
  const t = useI18n().errors.firebase.auth;

  const signInAnonymously = async () => {
    return await auth().signInAnonymously();
  };

  const toggleSignInModal = useCallback(() => {
    setOpenSignInModal(!openSignInModal);
  }, [openSignInModal]);

  const setProviderFacebookData = (
    providerData: (firebase.UserInfo | null)[] | undefined,
    pictureUrl: string
  ) => {
    if (!providerData) return;
    const providers = JSON.parse(JSON.stringify(providerData));

    const facebookProvider: firebase.UserInfo = providers?.find(
      (provider: firebase.UserInfo) => provider?.providerId === "facebook.com"
    );
    if (facebookProvider) {
      facebookProvider.photoURL = pictureUrl;
    }
    const parsedProviderData = providerData.map(data => {
      if (data?.providerId === "facebook.com" && facebookProvider) {
        return facebookProvider;
      }
      return data;
    });
    setProviderData(parsedProviderData);
  };

  const subscriber = useCallback(async (user: firebase.User | null) => {
    updateUser(user);
    const { uid: userId, isAnonymous: isUserAnonymous } = user ?? {};
    const { providerData: provider } = user ?? {};

    setIsAnonymous(!!isUserAnonymous);
    if (provider) {
      const { value } = await Storage.get({
        key: FACEBOOK_DATA
      });

      const additionalUserInfo = value ? JSON.parse(value) : {};
      setProviderFacebookData(provider, additionalUserInfo.picture?.data?.url);
    }
    if (userId) {
      setUid(userId);
      return;
    }
    signInAnonymously();
  }, []);

  useEffect(() => {
    const authSubscriber = auth().onAuthStateChanged(subscriber);
    if (!isAnonymous) setOpenSignInModal(false);
    return authSubscriber;
  }, [isAnonymous, subscriber]);

  const signInWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    return await auth().signInWithEmailAndPassword(email, password);
  };

  const sendPasswordResetEmail = async (email: string) => {
    return await auth().sendPasswordResetEmail(email);
  };

  const signInWithGoogle = async () => {
    const provider = new Services.GoogleAuthProvider();
    const response = await auth().signInWithPopup(provider);
    const { credential } = response;
    if (!credential || auth().currentUser?.isAnonymous) return response;
    await auth().signInWithCredential(credential);
    return response;
  };

  const signInWithFacebook = useCallback(async () => {
    const provider = new Services.FacebookAuthProvider();
    const response = await auth().signInWithPopup(provider);
    const profileInfo: any = response?.additionalUserInfo?.profile;
    await Storage.set({
      key: FACEBOOK_DATA,
      value: JSON.stringify(profileInfo)
    });

    const { credential } = response;
    if (!credential || auth().currentUser?.isAnonymous) return response;
    const data = await auth().signInWithCredential(credential);
    const { providerData } = data?.user ?? {};

    setProviderFacebookData(providerData, profileInfo.picture?.data?.url);

    return response;
  }, []);

  const registerWithEmailAndPassword = useCallback(
    async (email: string, password: string) => {
      const credential = Services.EmailAuthProvider.credential(email, password);
      const response = await auth().currentUser?.linkWithCredential(credential);
      if (response) subscriber(response.user);
      return response;
    },
    [subscriber]
  );

  const signInWithEmailLink = useCallback(
    async (email: string, emailLink: string) => {
      try {
        return await auth().signInWithEmailLink(email, emailLink);
      } catch (error) {
        throw new Error(getFirebaseAuthErrorMessage(t, error));
      }
    },
    [t]
  );

  const isSignInWithEmailLink = useCallback(
    (emailLink: string) => {
      try {
        return auth().isSignInWithEmailLink(emailLink);
      } catch (error) {
        throw new Error(getFirebaseAuthErrorMessage(t, error));
      }
    },
    [t]
  );

  const sendSignInLinkToEmail = useCallback(
    async (email: string) => {
      const url = process.env.NEXT_PUBLIC_MAGIC_LINK;
      if (!url) throw new Error("Missing resolve url");
      try {
        return await auth().sendSignInLinkToEmail(email, {
          url,
          handleCodeInApp: true
        });
      } catch (error) {
        throw new Error(getFirebaseAuthErrorMessage(t, error));
      }
    },
    [t]
  );

  const unlinkGoogle = useCallback(async () => {
    const provider = new Services.GoogleAuthProvider();
    const user = await auth().currentUser?.unlink(provider.providerId);
    if (user) subscriber(user);
  }, [subscriber]);

  const unlinkFacebook = useCallback(async () => {
    const provider = new Services.FacebookAuthProvider();
    const user = await auth().currentUser?.unlink(provider.providerId);
    if (user) subscriber(user);
  }, [subscriber]);

  const resetAuthContext = useCallback(() => {
    setIsAnonymous(false);
  }, []);

  const getEmail = () => {
    return auth()?.currentUser?.email;
  };

  const value: AuthProviderValue = useMemo(() => {
    return {
      isAnonymous,
      signInWithEmailAndPassword,
      signInWithGoogle,
      signInWithFacebook,
      signInAnonymously,
      sendPasswordResetEmail,
      registerWithEmailAndPassword,
      sendSignInLinkToEmail,
      signInWithEmailLink,
      isSignInWithEmailLink,
      uid,
      email: getEmail(),
      providerData,
      setProviderData,
      unlinkGoogle,
      unlinkFacebook,
      resetAuthContext,
      toggleSignInModal,
      openSignInModal
    };
  }, [
    isAnonymous,
    isSignInWithEmailLink,
    providerData,
    registerWithEmailAndPassword,
    resetAuthContext,
    sendSignInLinkToEmail,
    signInWithEmailLink,
    signInWithFacebook,
    uid,
    unlinkFacebook,
    unlinkGoogle,
    toggleSignInModal,
    openSignInModal
  ]);

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

export default AuthProvider;
