import styled from "styled-components";

import { SingleSignOnStyledProps as Props } from "./SingleSignOn.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const SingleSignOnStyled = styled.div<Props>`
  .SingleSignOn {
    &__icons {
      display: flex;
      flex-direction: column;
      padding-top: 3.2rem;
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10rem;
      width: 4.8rem;
      height: 4.8rem;
      cursor: pointer;

      svg {
        width: 1.6rem;
        height: 1.6rem;
      }
    }

    &__title {
      font-weight: 600;
      font-size: 2.4rem;
      color: var(--palette-white);
      text-align: center;
      width: 88%;
      margin: 0 auto;

      @media (width <= ${tablet}px) {
        font-size: 2rem;
      }
    }

    &__google {
      background-color: var(--palette-gray-s0-l95);
      width: auto;
      display: flex;
      justify-content: flex-start;
      padding-left: 2.4rem;
      margin-bottom: 1.6rem;

      &-label {
        font-weight: 600;
        font-size: 1.6rem;
        color: var(--palette-black-s0-l10);
        padding-left: 0.8rem;
      }
    }

    &__facebook {
      background-color: var(--palette-blue-s89-l52);
      width: auto;
      display: flex;
      justify-content: flex-start;
      padding-left: 2.4rem;
      margin-bottom: 1.6rem;

      svg {
        width: 2.4rem;
      }

      &-label {
        font-weight: 600;
        font-size: 1.6rem;
        color: var(--palette-white);
        padding-left: 0.8rem;
      }
    }

    &__magic {
      background-color: var(--palette-white);
      width: auto;
      display: flex;
      justify-content: flex-start;
      padding-left: 2.4rem;
      margin-bottom: 1.6rem;

      svg {
        width: 2.4rem;
        @media (width <= ${tablet}px) {
          margin-left: 2rem;
        }

        path {
          fill: var(--palette-primary);
        }
      }

      &-label {
        font-weight: 600;
        font-size: 1.6rem;
        color: var(--palette-primary);
        padding-left: 0.8rem;
      }

      @media (max-width: ${tablet}px) {
        margin-bottom: 0;
        padding-left: 0;
      }
    }

    &__apple {
      background-color: var(--palette-black);
      width: auto;
      display: flex;
      justify-content: flex-start;
      border-radius: 8rem;
      padding-left: 2.4rem;
    }
  }
`;

export default SingleSignOnStyled;
