import PlusCircleSVG from "images/plus-circle.svg";
import React from "react";

import Styles from "./AddNewRecordButton.styles";
import { AddNewRecordButtonProps as Props } from "./AddNewRecordButton.types";

const AddNewRecordButton: React.FC<Props> = props => {
  const { value, icon } = props;

  return (
    <Styles className="AddNewRecordButton" {...props}>
      {icon ? icon : <PlusCircleSVG />}
      <p className="AddNewRecordButton__value">{value}</p>
    </Styles>
  );
};

AddNewRecordButton.defaultProps = {};

export default AddNewRecordButton;
