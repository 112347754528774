import { useCountries } from "@simple/contexts";
import Head from "next/head";
import Script from "next/script";
import React, { memo, useLayoutEffect, useState } from "react";

import PrivacyModal from "../PrivacyModal/PrivacyModal";
import Styles from "./Layout.styles";
import { LayoutProps as Props } from "./Layout.types";
import MergeCartModal from "components/global/MergeCartModal/MergeCartModal";
import OutOfServiceModal from "components/global/OutOfServiceModal/OutOfServiceModal";
import ShareCartModal from "components/global/ShareCartModal/ShareCartModal";
import StoreNotification from "components/global/notifications/StoreNotification/StoreNotification";
import SignInModal from "components/signIn/SignInModal/SignInModal";
import { getENVs, firestore } from "config/artisn.config";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useSetupArtisn from "hooks/artisn/useSetupArtisn";
import useListeners from "hooks/useListeners";
import "config/services.config";

const { TITLE, FEATURE_FLAGS, ARTISN, APP } = CONSTANTS;
const { WITH_SHARE_SHOPPING_CART } = FEATURE_FLAGS;
const { WITH_ANONYMOUS_SHOPPING_CART_TOKEN } = FEATURE_FLAGS;
const { ACCOUNT_ID } = ARTISN;
const { WEB_URL } = APP;

const Layout: React.FC<Props> = props => {
  const { children } = props;
  useSetupArtisn();
  useListeners();
  const { locale } = useCountries().selectedCountry;
  const [disabledWeb, setDisabledWeb] = useState<boolean>();
  const { toggleSignInModal, openSignInModal } = useAuth();

  // useEffect(() => {
  //   if (isIOS) {
  //     Keyboard.setResizeMode({ mode: KeyboardResize.None });
  //   }

  //   if (isMobile) {
  //     StatusBar.setStyle({ style: Style.Light });
  //   }

  //   if (isAndroid) {
  //     StatusBar.setBackgroundColor({ color: variables.palette["white-hex"] });
  //   }
  // }, [isAndroid, isIOS, isMobile]);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       if (
  //         isMobile &&
  //         remoteVersionCode &&
  //         remoteVersionCode !== "0" &&
  //         remoteVersionCode !== VERSION_CODE
  //       ) {
  //         const semver = await import("semver");
  //         setUpdateApp(semver.default.gte(remoteVersionCode, VERSION_CODE));
  //         return;
  //       }
  //       setUpdateApp(false);
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   })();
  // }, [isMobile, remoteVersionCode]);

  useLayoutEffect(() => {
    firestore
      ?.collection("appParameters")
      .doc(`${ACCOUNT_ID}`)
      .onSnapshot(doc => {
        setDisabledWeb(doc.data()?.disabledWeb);
      });
  }, []);

  return (
    <Styles className="Layout">
      <Head>
        <meta name="viewport" content="initial-scale=1, viewport-fit=cover" />
        <title>{TITLE}</title>
      </Head>
      <Script
        defer
        src={`https://maps.googleapis.com/maps/api/js?key=${getENVs?.mapsApiKey}&libraries=places&language=${locale}`}
      />
      <OutOfServiceModal url={WEB_URL} open={disabledWeb} />
      {typeof disabledWeb !== "undefined" && !disabledWeb ? (
        <>
          {children}
          <SignInModal opened={openSignInModal} onClose={toggleSignInModal} />
          <StoreNotification />
          {WITH_SHARE_SHOPPING_CART ? <ShareCartModal /> : null}
          {WITH_ANONYMOUS_SHOPPING_CART_TOKEN ? <MergeCartModal /> : null}
          {/* {updateApp ? (
        <UpdateAppModal storeUrl={isIOS ? APPLE_STORE_URL : PLAY_STORE_URL} />
      ) : null} */}
          <PrivacyModal />
        </>
      ) : null}
    </Styles>
  );
};

Layout.defaultProps = {};

export default memo(Layout);
