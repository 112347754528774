import styled from "styled-components";

import { HeaderStyledProps as Props } from "./Header.types";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const HeaderStyled = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2.4rem;

  @media (max-width: ${tablet - 1}px) {
    justify-content: space-evenly;
  }

  .Header {
    &__logo {
      margin-right: 4.8rem;

      @media (max-width: ${tablet}px) {
        margin-right: 2.4rem;
      }
    }

    &__b2b {
      path {
        fill: var(--palette-white);
      }
    }
  }
`;

export default HeaderStyled;
