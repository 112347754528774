import { Storage } from "@capacitor/storage";
import { usePostMigrateAnonymousOrders } from "@simple/services";
import { sanitizeQueryParams } from "@simple/utils";
import { events } from "artisn/analytics";
import { getShoppingCart } from "artisn/shopping-cart";
import EmailSVG from "images/email-logo.svg";
import FacebookSVG from "images/facebook-filled.svg";
import GoogleSVG from "images/google-logo.svg";
import { useRouter } from "next/router";
import React from "react";

import Styles from "./SingleSignOn.styles";
import { SingleSignOnProps as Props } from "./SingleSignOn.types";
import useCheckout from "components/checkout/Checkout/context/checkout/checkout.context.hooks";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useI18n from "hooks/useI18n";
import { dismissErrorNotification } from "utils/notifications.utils";
import { createErrorNotification } from "utils/notifications.utils";

// TODO:* Uncomment when signInWithApple is implemented
// import AppleSVG from "images/apple.svg";

const { logSignIn, logSignUp } = events.auth;
const { logCustomEvent } = events;
const { ARTISN, STORAGE } = CONSTANTS;
const { SHOPPING_CART_DEFAULT_NAME } = ARTISN;
const { ANONYMOUS_SHOPPING_CART_TOKEN } = STORAGE;

const SingleSignOn: React.FC<Props> = props => {
  const t = useI18n();
  const { asPath, query, replace, push } = useRouter();
  const { currentForm = "signIn", className, isSignIn } = props;
  const { isAnonymous, toggleSignInModal } = useAuth();
  const { signInWithFacebook, signInWithGoogle } = useAuth();
  const { redirect } = sanitizeQueryParams(query);
  const { transferAnonymousId } = useCheckout();
  const auth = useAuth();
  const postMigrateAnonymousOrders = usePostMigrateAnonymousOrders(auth);
  const { mutate: migrateAnonymousOrders } = postMigrateAnonymousOrders;

  const handleRedirection = () => {
    const target = `${redirect ?? "/"}`;
    if (asPath === target) return;
    replace(target);
  };

  const setAnonymousCartStorage = async () => {
    const anonymousCart = await getShoppingCart({
      shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
      anonymous: isAnonymous
    });
    if (!anonymousCart) return;
    await Storage.set({
      key: ANONYMOUS_SHOPPING_CART_TOKEN,
      value: JSON.stringify(anonymousCart)
    });
  };

  const signInWithGoogleHandler = async () => {
    logCustomEvent("select_registration", {
      type: "Google",
      userType: isAnonymous ? "anonymous" : "registered"
    });
    try {
      setAnonymousCartStorage();
      const { user } = await signInWithGoogle();
      if (!user) throw new Error("No user was found with Google Provider");
      const { displayName, email } = user;

      if (currentForm === "signIn") {
        logSignIn({
          method: "Google",
          name: displayName ?? "",
          userType: isAnonymous ? "anonymous" : "registered"
        });
        if (transferAnonymousId) migrateAnonymousOrders(transferAnonymousId);
        handleRedirection();
        return;
      }
      logSignUp({
        method: "Google",
        name: displayName ?? "",
        email: email ?? "",
        userType: isAnonymous ? "anonymous" : "registered"
      });
      if (transferAnonymousId) migrateAnonymousOrders(transferAnonymousId);
      handleRedirection();
    } catch (e) {
      dismissErrorNotification();
      createErrorNotification(
        "No se encontró ningún usuario con el proveedor de Google"
      );
      console.error(e);
    }
  };

  const signInWithFacebookHandler = async () => {
    logCustomEvent("select_registration", {
      type: "Facebook",
      userType: isAnonymous ? "anonymous" : "registered"
    });
    try {
      setAnonymousCartStorage();
      const { user } = await signInWithFacebook();
      if (!user) throw new Error("No user was found with Facebook Provider");
      const { displayName, email } = user;

      if (currentForm === "signIn") {
        logSignIn({
          method: "Facebook",
          name: displayName ?? "",
          userType: isAnonymous ? "anonymous" : "registered"
        });
        if (transferAnonymousId) migrateAnonymousOrders(transferAnonymousId);
        handleRedirection();
        return;
      }
      logSignUp({
        method: "Facebook",
        name: displayName ?? "",
        email: email ?? "",
        userType: isAnonymous ? "anonymous" : "registered"
      });
      if (transferAnonymousId) migrateAnonymousOrders(transferAnonymousId);
      handleRedirection();
    } catch (e) {
      dismissErrorNotification();
      createErrorNotification(
        "No se encontró ningún usuario con el proveedor de Facebook"
      );
      console.error(e);
    }
  };

  const signInWithMagicLinkHandler = () => {
    toggleSignInModal();
    logCustomEvent("select_registration", {
      type: "Magic-link",
      userType: isAnonymous ? "anonymous" : "registered"
    });
    push("/signin-magic");
  };

  return (
    <Styles className={`SingleSignOn ${className}`}>
      <p className="SingleSignOn__title">
        {isSignIn
          ? t.auth.singleSignOn.selectLogin
          : t.auth.singleSignOn.selectRegister}
      </p>
      <div className="SingleSignOn__icons">
        <div
          className="SingleSignOn__google SingleSignOn__button"
          onClick={signInWithGoogleHandler}
        >
          <GoogleSVG />
          <p className="SingleSignOn__google-label">
            {t.auth.singleSignOn.signInGoogle}
          </p>
        </div>
        <div
          className="SingleSignOn__facebook SingleSignOn__button"
          onClick={signInWithFacebookHandler}
        >
          <FacebookSVG />
          <p className="SingleSignOn__facebook-label">
            {t.auth.singleSignOn.signInFacebook}
          </p>
        </div>
        <div
          className="SingleSignOn__magic SingleSignOn__button"
          onClick={signInWithMagicLinkHandler}
        >
          <EmailSVG />
          <p className="SingleSignOn__magic-label">
            {t.auth.singleSignOn.signInMagicLink}
          </p>
        </div>
        {/* TODO:* Add function to sign in with Apple
        <div
          className="SingleSignOn__apple SingleSignOn__button"
          onClick={() => {}}
        >
          <AppleSVG />
        </div>
        */}
      </div>
    </Styles>
  );
};

SingleSignOn.defaultProps = {
  className: ""
};

export default SingleSignOn;
