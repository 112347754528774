import { Store } from "artisn/types";
import EmptyStoresSVG from "images/empty-stores.svg";
import StoresErrorSVG from "images/stores-error.svg";
import React from "react";

import Styles from "./StoreList.styles";
import { StoreListProps as Props } from "./StoreList.types";
import CommonPlaceholder from "components/global/CommonPlaceholder/CommonPlaceholder";
import StorePreview from "components/global/StorePreview/StorePreview";

const StoreList: React.FC<Props> = props => {
  const { stores, isLoading, isError, selectedStore, query, className } = props;
  const { onStoreInfoClick, onStoreClick } = props;
  const empty = stores && stores.length === 0;

  const errorNode = (
    <div className="StoreList__error">
      <StoresErrorSVG />
      <p className="StoreList__error__message">
        Ocurrió un error al consultar las tiendas
      </p>
    </div>
  );

  const emptyNode = (
    <div className="StoreList__empty">
      <EmptyStoresSVG />
      <p className="StoreList__empty__title">Aún no tenemos tiendas</p>
      <p className="StoreList__empty__message">
        Vuelve pronto para encontrar una
      </p>
    </div>
  );

  const mapStores = (item: Store, index: number) => {
    const isSelected = selectedStore?.storeId === item.storeId;
    return (
      <StorePreview
        key={index}
        store={item}
        isSelected={isSelected}
        onContentClick={() => onStoreClick?.(item)}
        onInfoClick={() => onStoreInfoClick?.(item)}
      />
    );
  };

  const renderStores = () => {
    if (!query) return stores?.map(mapStores);
    return stores
      ?.filter(
        store =>
          store.storeName.toLowerCase().indexOf(query.toLowerCase()) !== -1
      )
      .map(mapStores);
  };

  const placeholdersNode = (
    <>
      <CommonPlaceholder />
      <CommonPlaceholder />
      <CommonPlaceholder />
      <CommonPlaceholder />
      <CommonPlaceholder />
    </>
  );

  return (
    <Styles className={`StoreList ${className}`}>
      {isLoading ? placeholdersNode : null}
      {empty ? emptyNode : null}
      {isError ? errorNode : null}
      {!isLoading && !empty ? renderStores() : null}
    </Styles>
  );
};

StoreList.defaultProps = {
  className: ""
};

export default StoreList;
