import styled from "styled-components";

import { SignInModalStyledProps as Props } from "./SignInModal.types";
import CONSTANTS from "config/constants";
import variables from "styles/util/variables";

const { mobile } = CONSTANTS.BREAKPOINTS;
const { primary } = variables.fonts;

const SignInModalStyled = styled.div<Props>`
  padding: 4rem;
  width: 62.8rem;
  height: 60rem;
  border-radius: 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  font-family: ${primary};
  background-color: var(--palette-primary);
  overflow-y: hidden;

  & > svg {
    path {
      fill: var(--palette-white);
    }
  }

  @media (max-width: ${mobile}px) {
    min-height: 88vh;
    width: 96vw;
    padding-top: 2.4rem;
  }

  .SignInModal {
    &__modal {
    }

    &__header {
      padding-top: 8%;
    }

    &__close {
      position: absolute;
      right: 1.6rem;
      top: 1.6rem;
      width: 2rem;
      height: 2rem;
      z-index: 2;
      cursor: pointer;
    }
  }
`;

export default SignInModalStyled;
