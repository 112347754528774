import B2bSVG from "images/signIn/big-b2b.svg";
import LogoSVG from "images/signIn/logo-pure-nature.svg";
import React from "react";

import Styles from "./Header.styles";
import { HeaderProps as Props } from "./Header.types";

const Header: React.FC<Props> = props => {
  const { className } = props;

  return (
    <Styles className={`Header ${className}`}>
      <LogoSVG className="Header__logo" />
      <B2bSVG className="Header__b2b" />
    </Styles>
  );
};

export default Header;
