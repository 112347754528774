// Project constants
import { Capacitor } from "@capacitor/core";
import { CONSTANTS as SHARED_CONSTANTS } from "@simple/utils";
import { ArtisnPlatform } from "artisn/types";

import { PickUpOption } from "types/common.types";

import PackageJSON from "../../package.json";

export const DEFAULT_PICK_UP_OPTION = {
  id: 0,
  name: "Quiero mi pedido ahora",
  forNow: true
};

export const PICK_UP_OPTIONS: PickUpOption[] = [
  DEFAULT_PICK_UP_OPTION,
  { id: 1, name: "Hora programada", forNow: false }
];

const WHATSAPP_PHONE_NUMBER = "";

const CONSTANTS = {
  ...SHARED_CONSTANTS,
  // HTML title tag text (Useful to display the brand name for SEO)
  TITLE: "Pure Nature",
  // Project configurations
  ARTISN: {
    ...SHARED_CONSTANTS.ARTISN
  },
  // REST API configurations
  API: {
    ...SHARED_CONSTANTS.API
  },
  // Activate or deactivate features
  FEATURE_FLAGS: {
    ...SHARED_CONSTANTS.FEATURE_FLAGS,
    // Allows the user to activate or deactivate the dark mode
    WITH_DARK_MODE: true,
    // Show AnonymousPasswordForm component in Checkout
    WITH_CHECKOUT_SIGNUP: false,
    // Activates the express checkout mode for payphone payments
    WITH_PAYPHONE_EXPRESS_CHECKOUT: false,
    // Whether loyalty functionality should be enabled
    WITH_LOYALTY: false,
    // Whether coupons functionality should be enabled
    WITH_COUPONS: true
  },
  STORAGE: {
    ...SHARED_CONSTANTS.STORAGE,
    PENDING_ORDER_TOKEN: "artisn-pending-order",
    BUSINESS_DATA: "business-data",
    FACEBOOK_DATA: "facebook-data",
    ANONYMOUS_SHIPPING_ADDRESS: "ANONYMOUS_SHIPPING_ADDRESS"
  },
  // CSS Break Points
  BREAKPOINTS: {
    mobile: 420,
    tablet: 768,
    desktop: 1024,
    wide: 1440,
    hd: 2560
  },
  // General settings
  GENERAL: {
    ...SHARED_CONSTANTS.GENERAL,
    // Platform running the application
    PLATFORM: Capacitor.getPlatform() as ArtisnPlatform,
    // App version code to enable update and support screen
    VERSION_CODE: PackageJSON.version
  },
  // Date and time format strings
  DATES_FORMAT: {
    HOUR_MINUTE: "HH:mm",
    COMPLETE_DATE_TIME: "YYYY-MM-DD HH:mm:ss"
  },
  INTEGRATIONS: {
    ...SHARED_CONSTANTS.INTEGRATIONS,
    WHATSAPP: {
      PHONE_NUMBER: WHATSAPP_PHONE_NUMBER,
      RETURN_TO_APP_URL: `https://api.whatsapp.com/send/?phone=${WHATSAPP_PHONE_NUMBER}&text&app_absent=0`
    }
  },
  EXTERNAL_URLS: {
    TERMS_URL:
      "https://plugins-pure-nature-media-dev.s3.us-east-2.amazonaws.com/docs/Terminos_legales_Pure_Nature.pdf",
    POLITICS_URL: "https://shop.purenature.com.ec/privacy",
    FOOTER_URL:
      "https://plugins-pure-nature-media-dev.s3.us-east-2.amazonaws.com/docs/Terminos_legales_Pure_Nature.pdf",
    DELIVERY_URL:
      "https://plugins-pure-nature-media-dev.s3.us-east-2.amazonaws.com/docs/Zonas_peligrosas_Despacho_Pedidos_Tramaco.pdf"
  },
  MAXIMUM_PHOTO_SIZE: 1000000,
  CONTACT_EMAIL: "atencion.cliente@petgourmet.com.ec",
  CONTACT_NUMBER: "+593 98 928 4441"
};

export default CONSTANTS;
