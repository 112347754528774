import { useFetchShippingAddresses } from "@simple/services";
import { ShippingAddress } from "artisn/types";
import CloseSVG from "images/close.svg";
import React, { useState } from "react";

import Styles from "./ShippingAddressModal.styles";
import { ShippingAddressModalProps as Props } from "./ShippingAddressModal.types";
import AddNewRecordButton from "components/global/AddNewRecordButton/AddNewRecordButton";
import Modal from "components/global/Modal/Modal";
import AddressFormModal from "components/profileAddresses/AddressFormModal/AddressFormModal";
import AddressList from "components/profileAddresses/AddressList/AddressList";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import { dismissErrorNotification } from "utils/notifications.utils";
import { createErrorNotification } from "utils/notifications.utils";

const { MAX_NUMBER_OF_SHIPPING_ADDRESSES } = CONSTANTS.CONSTRAINTS;

const ShippingAddressModal: React.FC<Props> = props => {
  const { onClose, locationOnly } = props;
  const auth = useAuth();
  const { isAnonymous } = auth;
  const [step, setStep] = useState(1);
  const { data: addressList } = useFetchShippingAddresses(auth);
  const [editAddress, setEditAddress] = useState<ShippingAddress>();

  const closeHandler = () => {
    setStep(1);
    onClose?.();
  };

  const validateNumberAddresses = () => {
    if ((addressList?.length ?? 0) >= MAX_NUMBER_OF_SHIPPING_ADDRESSES) {
      dismissErrorNotification();
      createErrorNotification(
        `Solo es posible tener ${MAX_NUMBER_OF_SHIPPING_ADDRESSES} direcciones activas`,
        "Límite de datos de direcciones superado"
      );
      return null;
    }
    setStep(2);
    setEditAddress(undefined);
  };

  const editAddressHandler = (address: ShippingAddress) => {
    setEditAddress(address);
    setStep(2);
  };

  if (step === 2 || isAnonymous) {
    return (
      <AddressFormModal
        {...props}
        onClose={closeHandler}
        locationOnly={locationOnly}
        editAddress={editAddress}
      />
    );
  }

  return (
    <Modal {...props} closeIcon={<CloseSVG />}>
      <Styles className="ShippingAddressModal">
        <header className="ShippingAddressModal__header">
          <p className="ShippingAddressModal__title">Dirección de entrega</p>
        </header>
        <div className="ShippingAddressModal__wrapper">
          <AddNewRecordButton
            onClick={validateNumberAddresses}
            value="Ingresar una nueva dirección"
          />
          <AddressList
            className="ShippingAddressModal__list"
            actions={["delete", "edit", "default"]}
            onSelect={closeHandler}
            onEdit={editAddressHandler}
          />
        </div>
      </Styles>
    </Modal>
  );
};

ShippingAddressModal.defaultProps = {};

export default ShippingAddressModal;
