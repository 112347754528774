import { useVendors } from "@simple/contexts";
import { events } from "artisn/analytics";
import { useEffect, useRef } from "react";

import CONSTANTS from "config/constants";
import useAnalytics from "contexts/analytics/analytics.context.hooks";
import useAuth from "contexts/auth/auth.context.hooks";

const { DEFAULT_VENDOR } = CONSTANTS.ARTISN;
const { logSetVendor, logChangeVendor } = events.vendor;

const useListenVendor = () => {
  const { analyticsInitialized } = useAnalytics();
  const { selectedVendorId } = useVendors();
  const { isAnonymous } = useAuth();
  const prevVendorId = useRef(selectedVendorId);

  useEffect(() => {
    if (!analyticsInitialized) return;

    logSetVendor({
      vendorId: DEFAULT_VENDOR.id,
      userType: isAnonymous ? "anonymous" : "registered"
    });
  }, [analyticsInitialized, isAnonymous]);

  useEffect(() => {
    if (!analyticsInitialized || prevVendorId.current === selectedVendorId) {
      return;
    }
    prevVendorId.current = selectedVendorId;

    logChangeVendor({
      previousVendorId: prevVendorId.current,
      nextVendorId: selectedVendorId,
      userType: isAnonymous ? "anonymous" : "registered"
    });
  }, [analyticsInitialized, isAnonymous, selectedVendorId]);
};

export default useListenVendor;
