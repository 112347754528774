// Form utility functions
import { DocumentType } from "artisn/types";
import * as yup from "yup";

import { SignInRucFormValues } from "components/SignInRuc/SignInRucForm/SignInRucForm.types";
import { CardFormValues } from "components/checkout/CardForm/CardForm.types";
import { allowedSymbols } from "components/global/PasswordStrength/PasswordStrength.helpers";
import { BillingDataFormValues } from "components/profileBilling/BillingDataForm/BillingDataForm.types";
import { UserFormValues } from "components/profileUser/UserForm/UserForm.types";
import { ConfirmDeleteAccountFormValues } from "components/profileUser/unsuscribe/UnsuscribeConfirm/UnsuscribeConfirm.types";
import { SignUpUserFormValues } from "components/signUpUser/SignUpUser/SignUpUserForm/SignUpUserForm.types";
import { BasicInternationalization } from "types/common.types";
import { FormShape } from "types/common.types";

export const optionSelectorGender = (t: BasicInternationalization) => [
  {
    id: 0,
    name: t.male
  },
  { id: 1, name: t.female }
];

export const validationMessages = {
  required: "Campo requerido",
  invalidEmail: "Correo electrónico no válido",
  minLength: (min: number) => `El campo debe tener ${min} caracteres mínimo`,
  maxLength: (max: number) => `El campo debe tener ${max} caracteres máximo`,
  onlyNumbers: "El campo debe contener solo números",
  onlyRUCNumber: "Debe ingresar un RUC válido",
  matchFields: "Los campos no coinciden",
  noMatchesEmail: "Los correos electrónicos no coinciden"
};

const { invalidEmail, required, maxLength, minLength } = validationMessages;
const { onlyNumbers, onlyRUCNumber, noMatchesEmail } = validationMessages;

export const validationRules = {
  requiredDocumentType: yup.string().required(required),
  email: yup.string().email(invalidEmail).trim(),
  matchEmailValidator: (emailCompare: string) => {
    return yup
      .string()
      .required(required)
      .email(invalidEmail)
      .oneOf([emailCompare], noMatchesEmail)
      .trim();
  },
  requiredString: yup
    .string()
    .required(required)
    .min(3, minLength(3))
    .max(50, maxLength(50))
    .trim(),
  requiredStringMild: yup
    .string()
    .required(required)
    .min(1, minLength(1))
    .max(50, maxLength(50))
    .trim(),
  requiredNumber: yup
    .number()
    .typeError(onlyNumbers)
    .required(required)
    .min(3, minLength(3))
    .max(50, maxLength(50)),
  requiredBoolean: yup.bool().oneOf([true], required),
  requiredEmail: yup
    .string()
    .required(required)
    .email(invalidEmail)
    .min(3, minLength(3))
    .max(50, maxLength(50))
    .trim(),
  requiredPhoneNumber: yup
    .string()
    .required(required)
    .matches(/^[0-9]+$/, onlyNumbers)
    .min(10, minLength(10))
    .max(10, maxLength(10)),
  customPhoneNumber: (min: number, max: number) =>
    yup
      .string()
      .required(required)
      .matches(/^[0-9]+$/, onlyNumbers)
      .min(min, minLength(min))
      .max(max, maxLength(max)),
  minLength: (min: number) =>
    yup.string().required(required).min(min, minLength(min)),
  maxLength: (max: number) =>
    yup.string().required(required).max(max, maxLength(max)),
  customName: (min: number, max: number) =>
    yup
      .string()
      .required(required)
      .min(min, minLength(min))
      .max(max, maxLength(max))
      .matches(
        /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/u,
        "El campo debe tener solo letras"
      )
      .trim(),
  password: yup
    .string()
    .test("len", minLength(8), value => (value?.toString().length ?? 0) >= 8)
    .matches(/[A-Z]/, "Debe contener al menos una letra mayúscula")
    .matches(/[0-9]/, "Debe contener al menos un número")
    .matches(
      new RegExp(allowedSymbols),
      "Debe contener al menos un caracter especial"
    )
    .trim(),
  twoOrMoreWords: (message?: string) =>
    yup
      .string()
      .required(required)
      .matches(/^[^\d]+$/, "El campo no debe contener números")
      .matches(
        /^[a-zA-Z\u00C0-\u1FFF]+\s+([a-zA-Z\u00C0-\u1FFF]+\s?)+/,
        message ?? "El campo debe contener mínimo dos palabras"
      ),
  checkBlankSpace: yup
    .string()
    .required(required)
    .min(6, "Debe contener al menos 6 caracteres")
    .max(50, maxLength(50))
    .matches(
      /^[^\s].+[^\s]$/,
      "El campo no puede contener espacios en blanco al inicio o al final"
    ),
  requiredBillingAddress: yup
    .string()
    .required(required)
    .min(3, minLength(3))
    .nullable(),
  documentRules: (documentType: DocumentType | undefined) => {
    switch (documentType) {
      case "CI":
        return yup
          .string()
          .required(required)
          .matches(/^\d+$/, { message: onlyNumbers })
          .typeError(onlyNumbers)
          .test(
            "len",
            "El campo debe tener 10 caracteres",
            value => value?.toString().length === 10
          )
          .nullable()
          .trim();
      case "RUC":
        return yup
          .string()
          .required(required)
          .matches(/(^\d+)(\d{0}001$)/, { message: onlyRUCNumber })
          .typeError(onlyNumbers)
          .test(
            "len",
            "El campo debe tener 13 caracteres",
            value => value?.toString().length === 13
          )
          .trim();
      default:
        return yup
          .string()
          .required(required)
          .min(1, minLength(1))
          .max(20, maxLength(20))
          .nullable();
    }
  }
};

export const trimFields = <T>(obj: T): T => {
  if (!obj) return obj;
  return JSON.parse(JSON.stringify(obj).replace(/"\s+|\s+"/g, '"'));
};

export const getMaxLength = (documentType: string | undefined) => {
  switch (documentType) {
    case "CI":
      return 10;
    case "RUC":
      return 13;
    default:
      return 20;
  }
};

const { requiredEmail, requiredString, requiredPhoneNumber } = validationRules;
const { documentRules, customName, requiredDocumentType } = validationRules;
const { twoOrMoreWords, requiredBillingAddress } = validationRules;
const { password: requiredPassword } = validationRules;

export const getUserFormSchema = (documentType: DocumentType | undefined) => {
  return yup.object().shape<FormShape<UserFormValues>>({
    name: customName(3, 50),
    lastname: customName(3, 50),
    businessName: requiredString,
    phone: requiredPhoneNumber,
    birthdate: yup.date(),
    documentType: requiredDocumentType,
    document: documentRules(documentType),
    email: requiredEmail,
    gender: undefined,
    commerceType: requiredString
  });
};

export const getCardFormSchema = (documentType: DocumentType | undefined) => {
  return yup.object().shape<FormShape<CardFormValues>>({
    documentType: requiredDocumentType,
    document: documentRules(documentType),
    name: customName(3, 50),
    lastName: customName(3, 50),
    email: requiredEmail
  });
};

export const getContactFormSchema = yup.object().shape({
  names: customName(3, 50),
  email: requiredEmail,
  phone: requiredPhoneNumber,
  subject: customName(3, 50),
  comments: customName(3, 100)
});

export const getSignUpFormSchema = (documentType?: DocumentType) => {
  const isRuc = documentType === "RUC";
  return yup.object().shape<FormShape<SignUpUserFormValues>>({
    document: documentRules(documentType),
    documentType: requiredDocumentType,
    businessName: isRuc ? requiredString : undefined,
    name: isRuc ? undefined : customName(3, 50),
    lastname: isRuc ? undefined : customName(3, 50),
    phone: requiredPhoneNumber,
    terms: yup.bool().oneOf([true], required).required(required),
    acceptPrivacyPolicies: yup.bool().oneOf([true], required).required(required)
  });
};

export const getBillingDataFormSchema = (documentType?: DocumentType) => {
  return yup.object().shape<FormShape<BillingDataFormValues>>({
    name: twoOrMoreWords(
      "El campo debe contener el nombre y el apellido o razón social"
    ),
    document: documentRules(documentType),
    documentType: requiredDocumentType,
    phone: requiredPhoneNumber,
    address: requiredBillingAddress
  });
};

export const signInRucFormSchema = yup
  .object()
  .shape<FormShape<SignInRucFormValues>>({
    document: documentRules("RUC"),
    businessName: requiredString,
    email: requiredEmail,
    password: requiredPassword
  });

export const SignUpFormSchema = yup
  .object()
  .shape<FormShape<SignInRucFormValues>>({
    email: requiredEmail,
    password: requiredPassword
  });

export const confirmDeleteAccountSchema = (emailUser: string) => {
  return yup.object().shape<FormShape<ConfirmDeleteAccountFormValues>>({
    email: validationRules.matchEmailValidator(emailUser)
  });
};
