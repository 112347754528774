import { useShoppingCart } from "@simple/contexts";

import { getSubtotalFromShoppingCart } from "utils/shoppingCart.utils";
import { calculateTaxes } from "utils/shoppingCart.utils";
import { getProductsSubtotalUtility } from "utils/shoppingCart.utils";

const useShoppingCartCalculations = () => {
  const { shoppingCart } = useShoppingCart();

  const { shippingCost, billTotal } = shoppingCart ?? {};

  const { netPrice: shippingCostNet = 0 } = shippingCost ?? {};

  // If back calculate totals we just use their billTotal object, otherwise we do
  // It happens when an benefit is applied
  if (billTotal && Object.keys(billTotal).length !== 0) {
    const { NORMAL } = billTotal;
    // @ts-ignore check for discountTotal when Update artisn SDK
    const { subtotalBeforeTaxesBeforeDiscounts = 0, discountTotal = 0 } =
      NORMAL ?? {};
    // @ts-ignore
    const { total = 0, taxTotal } = NORMAL ?? {};

    return {
      subTotal: subtotalBeforeTaxesBeforeDiscounts,
      totalDiscount: Number(discountTotal),
      shippingCostNet: shippingCostNet,
      calculatedTaxes: taxTotal ?? 0,
      totalToPay: +total.toFixed(2)
    };
  }

  const subTotalWithOutBenefit = getSubtotalFromShoppingCart(shoppingCart);

  const subTotalBenefit = getProductsSubtotalUtility(shoppingCart);
  const netSubTotal = subTotalWithOutBenefit ?? subTotalBenefit;
  const calculatedTaxes = +calculateTaxes(shoppingCart).toFixed(2);

  const totalToPay = calculatedTaxes + netSubTotal + shippingCostNet;

  return {
    subTotal: netSubTotal,
    totalDiscount: 0,
    shippingCostNet,
    calculatedTaxes,
    totalToPay: +totalToPay.toFixed(2)
  };
};

export default useShoppingCartCalculations;
