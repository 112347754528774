import { Modal } from "artisn-ui-react";
import CloseSVG from "images/close.svg";
import React from "react";

import Styles from "./SignInModal.styles";
import { SignInModalProps as Props } from "./SignInModal.types";
import Header from "components/global/Header/Header";
import SingleSignOn from "components/global/SingleSignOn/SingleSignOn";
import useAuth from "contexts/auth/auth.context.hooks";

const SignInModal: React.FC<Props> = props => {
  const { opened } = props;
  const { toggleSignInModal } = useAuth();

  return (
    <Modal
      className="SignInModal__modal"
      opened={opened}
      onClose={toggleSignInModal}
      color="transparent"
    >
      <Styles className="SignInModal">
        <Header className="SignInModal__header" />
        <CloseSVG
          width="20"
          height="20"
          viewBox="0 0 12 11"
          className="SignInModal__close"
          onClick={toggleSignInModal}
        />

        <SingleSignOn isSignIn className="SignIn__sso" />
      </Styles>
    </Modal>
  );
};

export default SignInModal;
