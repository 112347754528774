import styled from "styled-components";

import { ShoppingCartProductStyledProps as Props } from "./ShoppingCartProduct.types";
import { ShoppingCartProductPlaceholderProps as PlaceholderProps } from "./ShoppingCartProduct.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const ShoppingCartProductStyled = styled.div<Props>`
  display: grid;
  gap: 0.8rem;
  height: auto;
  padding: 0.8rem;
  padding-bottom: 1.2rem;
  border-bottom: 0.1rem solid var(--palette-gray-s0-l95);
  position: relative;
  margin-bottom: 1.6rem;

  .ShoppingCartProduct {
    &__modifyProduct {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      @media (max-width: ${tablet}px) {
        display: flex;
      }
    }

    &__button {
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--palette-gray-s0-l98);
      border: none;
      border-radius: 0.4rem;
      cursor: pointer;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.8;
      }
    }

    &__error {
      font-size: 1.2rem;
      color: var(--palette-red-s60-l60);
      line-height: 1.6rem;
      display: flex;
      align-items: center;
      padding-left: 1.2rem;

      @media (max-width: ${tablet}px) {
        grid-row: 2/3;
        grid-column: 1/3;
        padding-left: 0;
      }

      svg {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.6rem;

        path {
          fill: var(--palette-red-s60-l60);
        }
      }
    }

    &__counter {
      height: 3.2rem;
      margin-left: auto;
      background-color: var(--palette-white);
      border: 0.1rem solid var(--palette-purple-s48-l39);
      border-radius: 10rem;
      overflow: hidden;
      display: flex;

      & .Counter {
        &__button {
          width: 4.2rem;
          height: 3.2rem;
          background-color: var(--palette-white);

          svg {
            path {
              fill: var(--palette-purple-s48-l39);
            }
          }
        }

        &__quantity {
          background-color: var(--palette-white);
          color: var(--palette-black-s0-l10);
        }

        &__quantity,
        &__button:first-of-type {
          display: ${props => (!props.count ? "none" : "flex")};
        }
      }
    }
  }
`;

export const ShoppingCartProductPlaceholderStyled = styled.div<PlaceholderProps>`
  height: 16.6rem;
  display: flex;
  padding: 0.8rem;

  @media (max-width: ${tablet - 1}px) {
    height: 11.4rem;
  }

  .ShoppingCartProductPlaceholder {
    &__details {
      padding: 1.2rem 0.8rem;
    }

    &__image {
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      height: 10rem;
      overflow: hidden;
      position: relative;
      width: 10rem;
    }

    &__price {
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      height: 1.6rem;
      overflow: hidden;
      position: relative;
      width: 4.2rem;
    }

    &__name {
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      height: 1.6rem;
      overflow: hidden;
      position: relative;
      width: 18.4rem;

      @media (max-width: ${tablet - 1}px) {
        width: 16rem;
      }
    }

    &__description {
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      height: 1.6rem;
      overflow: hidden;
      margin-top: 0.8rem;
      margin-bottom: 1.2rem;
      position: relative;
      width: 7.2rem;
    }

    &__shine {
      animation: shine 2.5s ease-in-out infinite;
      background-color: var(--palette-gray-s0-l98);
      height: 300%;
      position: absolute;
      width: 25%;
      opacity: 0.4;
    }
  }
`;

export default ShoppingCartProductStyled;
